import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotaVentaService {
  private baseUrl: string;
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token")
      }),
    };
  }

  get_nv(acti_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "listado_nv",
      {
        acti_id: acti_id,
      },
      this.httpOptions
    );
  }

  get_activities(clie_id, cont_id, segty_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_activities",
      {
        clie_id: clie_id,
        cont_id: cont_id,
        segty_id: segty_id,
      },
      this.httpOptions
    );
  }

  nv_filter(acti_id, status, rety_name, fechaINI, fechaFIN): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "NVFilters",
      {
        acti_id: acti_id,
        status: status,
        rety_name: rety_name,
        fechaINI: fechaINI,
        fechaFIN: fechaFIN,
      },
      this.httpOptions
    );
  }

  getTypeReport(): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "list_rety",
      {
        clie_id: JSON.parse(sessionStorage.getItem("userData")).clie_id,
        token: sessionStorage.getItem("token"),
      },
      this.httpOptions
    );
  }

  // subida de archivos s3
  postUrl(arg, arg1, arg2, arg3, arg5 = null): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "archivo_U",
      {
        url: arg,
        name: arg1,
        type: arg2,
        compid: arg3,
        work_code: arg5,
      },
      this.httpOptions
    );
  }

  postFile(fileToUpload: FormData): Observable<any> {
    const formData: FormData = new FormData();
    return this.http.post<any>(this.baseUrl + "archivo", fileToUpload, {
      headers: new HttpHeaders({
        Authorization: "Bearer " + sessionStorage.getItem("token")
      }),
    });
  }

  get_files_s3(id, segty_id): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "get_files_s3",
      {
        id: id,
        segty_id: segty_id,
      },
      this.httpOptions
    );
  }

  getSubType(rety_id): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "reporte_subtype",
      { rety_id: rety_id },
      this.httpOptions
    );
  }

  postContid(cont_id, id): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "archivo_C",
      { id: id, cont_id: cont_id },
      this.httpOptions
    );
  }
  postDesc(arg, arg2): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "archivo_D",
      { id: arg2, desc: arg },
      this.httpOptions
    );
  }
}
