//const http = require('http');
const XLSX = require('xlsx'); 

export class ExcelService {

    constructor() { }
    
    jsonToExcel({ headers, data, filename = 'export' }){

        
        
        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    
        XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
    
        const columnsName = Object.keys(data[0]);
    
        const columnsWidth = columnsName.map((column) => {
            const width = data.reduce(
                (w, r) => Math.max(w, `${r[column]}`.length),
                10
            );
    
            return { wch: width };
        });
    
        worksheet['!cols'] = columnsWidth;
    
        XLSX.writeFile(workbook, `${filename}.xlsx`, { compression: true });

        
        /*
        const hostname = '127.0.0.1';
        const port = 7262;
        


        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([
        ["a","b","c"], [1,2,3]
        ]), "Sheet1");

        const server = http.createServer((req, res) => {
        const buf = XLSX.write(wb, { type:"buffer", bookType:"xlsx" });
        res.statusCode = 200;
        res.setHeader('Content-Disposition', 'attachment; filename="SheetJSNode.xlsx"');
        res.setHeader('Content-Type', 'application/vnd.ms-excel');
        res.end(buf);
        });

        server.listen(port, hostname, () => {
        console.log(`Server running at http://${hostname}:${port}/`);
        });
        */
    }

}