import { Component, NgModule,ViewEncapsulation } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';


@Component({
  selector: 'advanced-pie-chart',
  templateUrl: './advanced-pie-chart.component.html',
  styleUrls: ['./advanced-pie-chart.component.css'],

 
  styles: [''],
  encapsulation: ViewEncapsulation.None,

  
},)
export class AdvancedPieChartComponent {
  single: any[];
  view: any[] = [1000, 300];
  label = 'Total';
  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  
  colorSchemex = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  
  constructor() {
    
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  onSetLabel(label){
    this.label = label;
  }
  public setData(data){
    this.single = data;
  }
  labelFormat = (labelName) => {
    //Find the data by labelName from chartData 
    const dataPoint = this.single.find(x => x.name === labelName);
    
    //returns 10% for 'First' label, 50% for 'Second' label and so on.
    return `${dataPoint.value}%`;
  }
  valueFormat = (value)=>{
    return value;
  }
}