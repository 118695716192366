import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from './date-formater';
import { Component,OnInit, Input,Output,EventEmitter,ViewChild,ElementRef,HostBinding } from '@angular/core';

@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.html',
  styleUrls: ['./datepicker.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ]
})

export class DatepickerComponent implements OnInit {
    @Input() placeholder :string  = 'fecha';
    @Input() initialDate :string ;//= (new Date().toLocaleDateString().split('-')).reverse().join('-');
    @Input() disableInput :boolean = true;
    @Input() disabled :boolean = false;
    @Input() innerClass = '';
    @Input() filter = null;
    @Input() date = null;

    defaultFilter = null

    @Output() change = new EventEmitter<string>();
    @HostBinding('style.width') width = 'auto';

    @ViewChild('dateCtrl') dateCtrl :ElementRef;
   

    constructor(private dateAdapter:DateAdapter<any>){
        this.dateAdapter.setLocale('es-CH');
        this.defaultFilter = (d: Date): boolean => {
            return true;
        }
        this.filter = this.defaultFilter;
    }

    ngOnInit(){
        console.log(this.dateCtrl);
    }

    onChange(event){
      console.log('evento notificado por datepicker');
        this.change.emit(event);
    }
   
    clearDate(event) {
      event.stopPropagation();
      this.date = null;
    }
}