//const http = require('http');
const XLSX = require("xlsx");

export class ExcelService {
  constructor() {}

  jsonToExcel({ headers, data, filename = "export" }) {
    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);

    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });

    const columnsName = Object.keys(data[0]);

    const columnsWidth = columnsName.map((column) => {
      const width = data.reduce(
        (w, r) => Math.max(w, `${r[column]}`.length),
        10
      );

      return { wch: width };
    });

    worksheet["!cols"] = columnsWidth;

    XLSX.writeFile(workbook, `${filename}.xlsx`, { compression: true });
  }
}
