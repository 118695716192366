import { Component, OnInit, Renderer2, Input, Output, TemplateRef, EventEmitter, ViewChild, ElementRef,ComponentFactoryResolver, ViewContainerRef, AfterViewInit, ChangeDetectorRef, HostListener   } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SnotifyService } from 'ng-snotify';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { FormBuilder, FormGroup,FormControl,Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material';
import { PrintService } from './../../../../services/print.service'
import { DataTableComponent } from './../../../dashboard/data-table/data-table.component';
import { HorizontalChartComponent } from '../../../dashboard/charts/horizontal-chart/horizontal-chart.component';
import { LineChartComponent } from '../../../dashboard/charts/line-chart/line-chart.component';
import { ViewImageModalComponent } from './../../view-inage-modal/view-image-modal.component';
import { VerticalChartComponent } from '../../../dashboard/charts/vertical-chart/vertical-chart.component';

import * as moment from 'moment';
import { TableAndChartHandlerComponent } from 'src/app/components/dashboard/table-and-chart-handler/table-and-chart-handler.component';

import { GroupedVerticalChartComponent } from 'src/app/components/dashboard/charts/grouped-vertical-chart/grouped-vertical-chart.component';
import { StackgroupHorizontalChartComponent } from 'src/app/components/dashboard/charts/stackgroup-horizontal-chart/stackgroup-horizontal-chart.component';

import { DashboardFilterKdmComponent } from 'src/app/components/dashboard_html/kdm/gestion-residuos/dashboard-filter/dashboard-filter.component';
import { StackedVerticalChartComponent } from 'src/app/components/dashboard/charts/stacked-vertical-chart/stacked-vertical-chart.component';
import { PieChartComponent } from '../../../dashboard/charts/pie-chart/pie-chart-component';
import { AdvancedPieChartComponent } from '../../../dashboard/charts/advanced-pie-chart/advanced-pie-chart.component';
import { saveAs } from 'file-saver/';
import { ExcelService } from './../../../../services/excel.service'

export interface Mes {
  value: number;
  nombre: string;
}

export interface Anio {
  value: number;
  nombre: number;
}

export interface contratos {
  value: number;
  cont_name: string;
  cont_code: string;
}

export interface residuos {
  value: number;

}

export interface graphic {
  value: number;
  daphagra_title: string;
}

@Component({
  selector: 'app-gestion-residuos',
  templateUrl: './gestion-residuos.component.html',
  styleUrls: ['./gestion-residuos.component.css']
})
export class GestionResiduosComponent extends TableAndChartHandlerComponent implements OnInit,AfterViewInit {

  public user_id = JSON.parse(sessionStorage.getItem('userData')).user_id;
  public clie_id = JSON.parse(sessionStorage.getItem('userData')).clie_id;

  public loadingServices = 0;
  public listServicios = '';
  
  noSpeciesGroups = false;
  maxPlaguePresence = 0;
  filters = {};
  

  month: Mes[] = [
    {value: 1, nombre: 'Enero'},
    {value: 2, nombre: 'Febrero'},
    {value: 3, nombre: 'Marzo'},
    {value: 4, nombre: 'Abril'},
    {value: 5, nombre: 'Mayo'},
    {value: 6, nombre: 'Junio'},
    {value: 7, nombre: 'Julio'},
    {value: 8, nombre: 'Agosto'},
    {value: 9, nombre: 'Septiembre'},
    {value: 10, nombre: 'Octubre'},
    {value: 11, nombre: 'Noviembre'},
    {value: 12, nombre: 'Diciembre'},
  ];

  year: Anio[] = [
    {value: 2017, nombre: 2017},
    {value: 2018, nombre: 2018},
    {value: 2019, nombre: 2019},
    {value: 2020, nombre: 2020},
    {value: 2021, nombre: 2021},
    {value: 2022, nombre: 2022},
    {value: 2023, nombre: 2023},
    {value: 2024, nombre: 2024},
    {value: 2025, nombre: 2025},
  ];

  dateNow = '';
  dateLast = '';
  public taskPermission = {
    allowed: false,
    initialDate: 1,
    finalDate: 30,
    showTasksMonthsAgo:12
  };

  
  public dataContract:contratos[]=[];
  public contratosTodos=[];
  public dataGraphic:graphic[]=[];
  public dataResiduos:residuos[]=[];
  public dataPlanta:residuos[]=[];
  public dataTipoDisposicion:residuos[]=[];
  public dataContracts:residuos[]=[];


 
  barMaxWidth = 15;

  formatNumberSeparator(val) {
    if (val >= 0) {
      return new Intl.NumberFormat("es-CL").format(val);
    }
    return val;
  } 
  //Modal Añadir comentario
  public modalCommentDash: BsModalRef;
  public description;
  public dashboard_name;
  //Ver comentarios
  public modalViewCommentDash: BsModalRef;  
  public pagina: number = 1;
  public porPagina: number = 4;   // cantidad de filas a mostrar en la paginacion
  public porPaginaListadoTarea: number = 20;
  public paginaListadoTarea: number = 1;
  public editComment = false;
  //Edicion de comentario
  public descriptionEdicion;
  public anio;
  public mes;
  public fecha;
  public id_table_to_edit;
  public dadabikd_id; //
  //edicion comentario actividad roedores

  // edicion de comentario captura segun mes
  public coancadime_commentary;
  // edicion de comentario consumo segun mes
  public coancodime_commentary;
  //TUV mensual
  public daprsametu_commentary;
  //TUV semanal
  public daprsasetu_commentary;
  // listado de comentario actividad roedores
  public listCommentActiRoeMesZona;
  public listCommentCompAnualCapturaMes;
  public listCommentCompAnualConsumoMes;
  public loadingCommentActiRoeMesZona = 0;
  public loadingCommentCompAnualCapturaMes = 0;
  public loadingCommentCompAnualConsumoMes = 0;
  public loadingTuvModal = false;
  // listado de comentario tUV



  public listAllServices;
  public loadingAllServices = 0;
  
  displayedColumns: string[] = ['date','patente', 'trabajador', 'tipoVisita', 'nGuia','fechaRelleno','nIngresoRelleno','tonIngeso','dispFinal','patenteDisp','tipoResiduo' ,'acciones'];
  pageSizeOptions: number[] = [15, 25, 50, 75];
  @ViewChild('allServicesSort', {read: MatSort}) allServicesSort: MatSort;
  @ViewChild('paginatorAllResiduos', {read: MatPaginator}) paginatorAllResiduos: MatPaginator;
  public servicesPage = 1;


  //visualizacion graficos / tablas
  public viewGraphic = true;
  public loadingEdit = 0;
  //public cant_pending_task = 0;
  //public cant_pending_task_all =0;
  //public task_list_size = 0;
  public showsServicesTable =0;
  public clients = [];
  //loagin grafico modal template
  public loadingGraphicModal = 0;
  public loadingTuv;
  public loadingDeviceActivity = { perimetroExterior: false, perimetroEstructural: false, interior: false };
 
  //configuracion del modal
  public config = {
    backdrop: true , //Fondo oscuro 
    ignoreBackdropClick: true, //para que no se cierre al clickear fuera del formulario 
    class: 'modal-lg',
    keyboard: false //para que no se cierre al apretar escape 
  };
  public selectDates = {
    detection: ''
    , verification: ''
  };
  detectionDateChange(event) {
    this.selectDates['detection'] = (event.value.toLocaleDateString().split('-')).reverse().join('-');
    //this.filters();
  }
  //count listado done comentario
  public countActiRoeMesZona = 0;
  public countCompAnualCapturaMes = 0;
  public countCompAnualConsumoMes = 0;
  public countTUVSemanaPlaga = 0;
  public countTUVMensual = 0;
  public countTUVSemanal = 0;
  

  public contractsListText : string = 'Ninguno'


  public selectedContract;
  public servicesOptions;
  public residuosServicesList = [];
  //
  public deviceCharts = [];
  public maxDeviceValue = 0;
  
  public titles = {
    residuosPlanta:'Cargando...',
    residuosTipo:'Cargando...',
    residuosTipoTratamiento:'Cargando...',
    residuosGestionadoAnio:'Cargando...'
  }

  @ViewChild('testRef') testRef: ElementRef;
  
  
  @ViewChild('residuosPlantaChartContainer', {read: ViewContainerRef}) residuosPlantaChartContainer: ViewContainerRef;
  @ViewChild('residuosTipoChartContainer', {read: ViewContainerRef}) residuosTipoChartContainer: ViewContainerRef;
  @ViewChild('residuosTipoTratamientoChartContainer', {read: ViewContainerRef}) residuosTipoTratamientoChartContainer: ViewContainerRef;
  @ViewChild('residuosGestionadoAnioChartContainer', {read: ViewContainerRef}) residuosGestionadoAnioChartContainer: ViewContainerRef;
  //@ViewChild('residuosServicesChartContainer', {read: ViewContainerRef}) residuosServicesChartContainer: ViewContainerRef;
  
  
  
  //filters
  @ViewChild('contractFilters') contractsFilters: DashboardFilterKdmComponent;
  @ViewChild('residuosPlantaFilter') residuosPlantaFilter: DashboardFilterKdmComponent;
  @ViewChild('residuosTipoFilter') residuosTipoFilter: DashboardFilterKdmComponent;
  @ViewChild('residuosTipoTratamientoFilter') residuosTipoTratamientoFilter: DashboardFilterKdmComponent;
  @ViewChild('residuosGestionadosAnioFilter') residuosGestionadosAnioFilter: DashboardFilterKdmComponent;
  @ViewChild('residuosServicesFilter') residuosServicesFilter: DashboardFilterKdmComponent;

  

  chartFilters = {
    'residuosPlanta' : {'contracts':'','desde':this.dateNow,'hasta':this.dateLast,'tipoResiduo':[],'planta':[],'tipoDisposicion':[]}
    ,'residuosTipo' :  {'contracts':'','desde':'','hasta':'','tipoResiduo':[]}
    ,'residuosTipoTratamiento' :  {'contracts':'','desde':'','hasta':'','tipoDisposicion':[]}
    ,'residuosGestionadosAnio' :  {'years':[],'months':[],'contracts':'','desde':'','hasta':'','tipoResiduo':[]}
    ,'residuosServices' :  {'contracts':'','desde':'','hasta':''}
  };

  constructor(
    private valPermiso: ValidaPermisosService,
    private dashboard: DashboardService,
    private http:HttpClient,
    private route:Router,
    private notify: SnotifyService,
    private modalService: BsModalService,
    public printService : PrintService,
    public cfResolver : ComponentFactoryResolver,
    public cdRef : ChangeDetectorRef ,
    public formBuilder : FormBuilder,
    public servicesContainer: ViewContainerRef,
    public excelService : ExcelService,
    ) { 
      super(cfResolver);

      let date = new Date()
      let year = date.getFullYear()
      let day = `${date.getDate()}`;
      let month = `${date.getMonth() + 1}`;
      if(date.getDate()<10) day = `0${day}`;
      if((date.getMonth() + 1)<10) month = `0${month}`;
      this.dateNow = `${year}-${month}-${day}`;
      this.dateLast = this.addDaysDate(this.dateNow,-30);

      this.chartFilters['residuosPlanta']['desde'] = this.dateLast;
      this.chartFilters['residuosPlanta']['hasta'] = this.dateNow;

      this.chartFilters['residuosTipo']['desde'] = this.dateLast;
      this.chartFilters['residuosTipo']['hasta'] = this.dateNow;
      
      this.chartFilters['residuosTipoTratamiento']['desde'] = this.dateLast;
      this.chartFilters['residuosTipoTratamiento']['hasta'] = this.dateNow;
      
      this.chartFilters['residuosGestionadosAnio']['desde'] = this.dateLast;
      this.chartFilters['residuosGestionadosAnio']['hasta'] = this.dateNow;

      this.chartFilters['residuosServices']['desde'] = this.dateLast;
      this.chartFilters['residuosServices']['hasta'] = this.dateNow;

    }// FIN DEL CONSTRUCTOR

  nextServicesPage(){
    this.loadResiduoservicios(null,this.servicesPage+1);
  }

  prevServicesPage(){
    this.loadResiduoservicios(null,this.servicesPage-1);
  }



  /*
  openTask(){
    this.viewGraphic = false;
    $('.charts-container').hide(1000,function(){
      $('.tasks-container').show(1000);
    });
    $('#btnGraphic').removeClass('active');
    
    if( typeof this.listAllServices === "undefined" ){
      this.loadResiduoservicios(null,1); 
    }
  }
  */
 /*
  openGraphic(){
    this.viewGraphic = true;
    $('.tasks-container').hide(1000,function(){
      $('.charts-container').show(1000);
    });
    $('#btnTask').removeClass('active');
    // $('#btnGraphic').addClass('active');
  }*/

 

  graphicActiRoedores(){
    $('.app_menu').css({"max-height":"3000px"});
  }

  graphicTUV(){
    $('.app_menu').css({"max-height":"2700px"});
  }

  showImageService(id,anio,mes,table,related_id,cont_id,tag){

    this.anio = anio;
    this.mes = mes;
    this.dadabikd_id = id;
    this.id_table_to_edit = related_id;
    

    this.modalViewCommentDash = this.modalService.show(ViewImageModalComponent, this.config);

  
    var filter = {
     
    }

    this.dashboard.getresiduosImagen(id).subscribe(
      dato=>{   
        this.modalViewCommentDash.content.setData(dato)
         
       
        this.modalViewCommentDash.content.setData(dato['images']);
        this.modalViewCommentDash.content.setLoading(false);
        this.modalViewCommentDash.content.setType("complete");
        
        
        this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
          this.servicesContainer = ref;
          if (this.servicesContainer) {
            
          }
        })
                      
    },error=>{
      console.log(error);
      this.loadingAllServices= 3;
      this.notify.warning("Error al cargar imagenes:\nConexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })   
      
  }


  showLoading(){
    this.loadingDeviceActivity = { perimetroExterior: true, perimetroEstructural: true, interior: true };
  }


  aplyFiltersToAllCharts(){

    this.loadResiduosPlantaChart();
    this.loadResiduosTipoChart();
    this.loadResiduosTipoTratamientoChart();
    this.loadResiduosGestionadoAnioChart();
    this.loadResiduoservicios(null,1);
    
  }//filtros


  searchTableResiduos(filterValue) {
    this.listAllServices.filter = filterValue.trim().toLowerCase();
  }

  validaPermisos(permiso){
      return this.valPermiso.validaPermisos(permiso);
  }

  getNumberMonth(mes){
    if(mes == 'Enero'){ return '01'}
    if(mes == 'Febrero'){ return '02'}
    if(mes == 'Marzo'){ return '03'}
    if(mes == 'Abril'){ return '04'}
    if(mes == 'Mayo'){ return '05'}
    if(mes == 'Junio'){ return '06'}
    if(mes == 'Julio'){ return '07'}
    if(mes == 'Agosto'){ return '08'}
    if(mes == 'Septiembre'){ return '09'}
    if(mes == 'Octubre'){ return '10'}
    if(mes == 'Noviembre'){ return '11'}
    if(mes == 'Diciembre'){ return '12'}
  }

  ngAfterViewInit(){
    $('.app_menu').css({"max-height":"2700px"});

    this.dashboard.getTasksPermission().subscribe(
      data => {
        this.taskPermission = data['taskPermission'];
        sessionStorage.setItem("showTasksMonthsAgo",data['taskPermission']["showTasksMonthsAgo"]);
      } 
    )
    
    this.loadResiduosFiltrosKdm();

    this.dashboard.get_contracts_profile(this.user_id,'gestion-residuos').subscribe(
      data=>{

          this.dataContract  = data;    
          this.contratosTodos = data;
          this.selectedContract = data[0].value;

          this.setFilterContracts(this.residuosPlantaFilter,this.dataContract);
          this.setFilterContracts(this.residuosTipoFilter,this.dataContract);
          this.setFilterContracts(this.residuosTipoTratamientoFilter,this.dataContract);
          this.setFilterContracts(this.residuosGestionadosAnioFilter,this.dataContract);
          this.setFilterContracts(this.residuosServicesFilter,this.dataContract);
                
          var currentYear = new Date().getFullYear();
          //this.residuosPlantaFilter.setDefaultYears(currentYear);
          var currentMonth = (new Date().getMonth()) +1;
          //this.residuosPlantaFilter.setDefaultMonths([currentMonth]);

          //this.residuosServicesFilter.setDefaultContract( data[0].value );
          //this.updateContracts(data[0].value, 'SET_DEFAULT');

          this.aplyFiltersToAllCharts();

          this.servicesOptions = {
            years : []
            , months : []
            , contracts: [data[0].value]
          };
          this.loadResiduoservicios(null,1);
          
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  


    this.dashboard.listGraphicTittle().subscribe(
        data=>{
          this.dataGraphic  = data;
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  

  }



  parseContracts(contracts){
    var contractsList = [];
    for( let contract of contracts){
      contractsList.push({ id: contract.value, value: contract.cont_name, code: contract.cont_code });
    }
    return contractsList;
  }

  setFilterContracts( filter :DashboardFilterKdmComponent ,contracts){
    filter.setData('contract', this.parseContracts(contracts) );
  }
  setShowContracts( filter :DashboardFilterKdmComponent ,value){
    filter.setShowContracts(value);
  }
  setShowDisposition( filter :DashboardFilterKdmComponent ,value){
    filter.setShowDisposition(value);
  }
  setShowTipoResiduo( filter :DashboardFilterKdmComponent ,value){
    filter.setShowTipoResiduo(value);
  }
  setShowTipoDisposicion( filter :DashboardFilterKdmComponent ,value){
    filter.setShowTipoDisposicion(value);
  }
  setFilterResiduos( filter :DashboardFilterKdmComponent ,residuos){
    //filter.setData('residuos', residuos );
    filter.setData('tipoResiduo', residuos );
  }
  setFilterPlanta( filter :DashboardFilterKdmComponent ,planta){
    filter.setData('planta', planta );
  }
  setFilterDesde( filter :DashboardFilterKdmComponent ,desde){
    filter.setData('desde', desde );
  }
  setFilterHasta( filter :DashboardFilterKdmComponent ,hasta){
    filter.setData('hasta', hasta );
  }
  setFilterTipoDisposicion( filter :DashboardFilterKdmComponent ,tipoDisposicion){
    filter.setData('tipoDisposicion', tipoDisposicion );
  }

  ngOnInit() {    
    this.filters = { desde:'',hasta:'',tipoResiduo:[] , planta: [], tipoDisposicion: [],"month":[],"year":[] };
  }


  onPrintGraphic(graphicName){
    var f = null;
    if(graphicName=='ResiduosPlanta'){
      f = this.chartFilters['residuosPlanta'];  
    }else if(graphicName=="ResiduosTipo"){
      f = this.chartFilters['residuosTipo'];  
    }else if(graphicName=="ResiduosTipoTratamiento"){
      f = this.chartFilters['residuosTipoTratamiento'];  
    }else if(graphicName=="ResiduosGestionadosAnio"){
      f = this.chartFilters['residuosGestionadosAnio'];  
    }else if(graphicName=="residuosServices"){
      f = this.chartFilters['residuosServices'];
    }
   
    console.log(f)
    $(".btn-print").prop('disabled',true);
    this.notify.info('Espere mientras se genera su documento...',{timeout:2500})
    this.printService.printDocument('graphic', {
        graphic : graphicName,
        desde : f.desde,
        hasta : f.hasta,
        tipoResiduo : f.tipoResiduo,
        planta : f.planta,
        tipoDisposicion:f.tipoDisposicion,
        contIds:f.contracts
    });
  }

loadResiduosFiltrosKdm(){
  this.dashboard.getresiduosFiltrosKdm().subscribe(
    data=>{

      this.dataResiduos  = data['tipo_residuo'];
      this.dataPlanta  = data['planta'];
      this.dataTipoDisposicion  = data['tipo_disposicion'];
      this.dataContracts = data['contracts'];
      
      this.setShowContracts(this.residuosPlantaFilter,true)
      this.setFilterResiduos(this.residuosPlantaFilter,this.dataResiduos);
      this.setFilterPlanta(this.residuosPlantaFilter,this.dataPlanta);
      this.setFilterDesde(this.residuosPlantaFilter,this.dateLast);
      this.setFilterHasta(this.residuosPlantaFilter,this.dateNow);
      this.setFilterTipoDisposicion(this.residuosPlantaFilter,this.dataTipoDisposicion);
      this.setShowDisposition(this.residuosPlantaFilter,true);
      this.setShowTipoResiduo(this.residuosPlantaFilter,true);
      this.setShowTipoDisposicion(this.residuosPlantaFilter,false);

      this.setShowContracts(this.residuosTipoFilter,true)
      this.setFilterResiduos(this.residuosTipoFilter,this.dataResiduos);
      this.setFilterPlanta(this.residuosTipoFilter,this.dataPlanta);
      this.setFilterDesde(this.residuosTipoFilter,this.dateLast);
      this.setFilterHasta(this.residuosTipoFilter,this.dateNow);
      this.setFilterTipoDisposicion(this.residuosTipoFilter,this.dataTipoDisposicion);
      this.setShowDisposition(this.residuosTipoFilter,false);
      this.setShowTipoResiduo(this.residuosTipoFilter,true);
      this.setShowTipoDisposicion(this.residuosTipoFilter,false);
      
      this.setShowContracts(this.residuosTipoTratamientoFilter,true)
      this.setFilterResiduos(this.residuosTipoTratamientoFilter,this.dataResiduos);
      this.setFilterPlanta(this.residuosTipoTratamientoFilter,this.dataPlanta);
      this.setFilterDesde(this.residuosTipoTratamientoFilter,this.dateLast);
      this.setFilterHasta(this.residuosTipoTratamientoFilter,this.dateNow);
      this.setFilterTipoDisposicion(this.residuosTipoTratamientoFilter,this.dataTipoDisposicion);
      this.setShowDisposition(this.residuosTipoTratamientoFilter,false);
      this.setShowTipoResiduo(this.residuosTipoTratamientoFilter,false);
      this.setShowTipoDisposicion(this.residuosTipoTratamientoFilter,true);

      this.setShowContracts(this.residuosGestionadosAnioFilter,true)
      this.setFilterResiduos(this.residuosGestionadosAnioFilter,this.dataResiduos);
      this.setFilterPlanta(this.residuosGestionadosAnioFilter,this.dataPlanta);
      this.setFilterDesde(this.residuosGestionadosAnioFilter,this.dateLast);
      this.setFilterHasta(this.residuosGestionadosAnioFilter,this.dateNow);
      this.setFilterTipoDisposicion(this.residuosGestionadosAnioFilter,this.dataTipoDisposicion);
      this.setShowDisposition(this.residuosGestionadosAnioFilter,false);
      this.setShowTipoResiduo(this.residuosGestionadosAnioFilter,true);
      this.setShowTipoDisposicion(this.residuosGestionadosAnioFilter,false);

      this.setFilterDesde(this.residuosServicesFilter,this.dateLast);
      this.setFilterHasta(this.residuosServicesFilter,this.dateNow);
      
    }
    , error=>{ console.log(error); this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,}); }) 
}


loadResiduosPlantaChart(container = null, filters = null)
{
  
  var f = filters!=null ? filters : this.chartFilters['residuosPlanta'];
  
  container = container!=null ? container : this.residuosPlantaChartContainer;

  this.noSpeciesGroups = false;
  this.maxPlaguePresence = 0;
  
  var residuosPlantaChart :HorizontalChartComponent = this.createHorizontalChart(container,'disposicion','Cargando...',['col-lg-12']);
  
  this.dashboard.getresiduosPlanta(f.contracts,f.desde,f.hasta,f.planta,f.tipoResiduo,f.tipoDisposicion).subscribe(
    data=>{
      
      let table = data['table'];
      
      residuosPlantaChart.style.legend = true;
      residuosPlantaChart.style.legendTitle = 'Residuos';
      residuosPlantaChart.style.yAxisLabel = 'Planta';
      residuosPlantaChart.style.xAxisLabel = 'Kilos';
      residuosPlantaChart.title = 'Residuos gestionados';
      //residuosPlantaChart.setCustomizedLegend(true);
      residuosPlantaChart.setDataSource(data['chart']); 
      residuosPlantaChart.xAxisTickFormatting = this.formatNumberSeparator;
      var total = table.map(t => t.Kilos).reduce((acc, value) => acc + value, 0);
      table = this.formatDataTable(table,'Kilos');
     
      this.titles.residuosPlanta = data['title'];
      
      var residuosPlantaTable = this.createTable(container,'',[],['col-lg-12']);
      residuosPlantaTable.displayAllColumns = true;
      residuosPlantaTable.paddingLeft = '80px';
      residuosPlantaTable.paddingRight = '170px';
      residuosPlantaTable.fontSize = 10;
      residuosPlantaTable.setDataSource(table);
      residuosPlantaTable.setDisplayFooter(true);
      
      total = this.formatNumberSeparator(total);
      residuosPlantaTable.setFooters(['Total','','',total]);
      
    }
    , error=>{ console.log(error); this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,}); })
}


loadResiduosTipoChart(container = null, filters = null)
{
    
  var f = filters!=null ? filters : this.chartFilters['residuosTipo'];
  container = container!=null ? container : this.residuosTipoChartContainer;
  
  
  this.noSpeciesGroups = false;
  this.maxPlaguePresence = 0;
  
  //var residuosTipoPieChart :AdvancedPieChartComponent = this.createPieChart(container,'plaga','Cargando...',['col-lg-12']);
  var residuosTipoGroupedChart = this.createGroupedVerticalChart(container, 'residuos', '', ['col-lg-12']);
  
  
  this.dashboard.getresiduosTipo(f.contracts,f.desde,f.hasta,f.planta,f.tipoResiduo,f.tipoDisposicion).subscribe(
    data=>{
      
      residuosTipoGroupedChart.style.legend = true
      residuosTipoGroupedChart.style.showXAxisLabel = true;
      residuosTipoGroupedChart.style.xAxisLabel = 'Residuos';
      residuosTipoGroupedChart.style.showYAxisLabel = true;
      residuosTipoGroupedChart.style.yAxisLabel = 'Kilos'
      residuosTipoGroupedChart.title = 'Tipo de Residuos gestionados';
      //residuosTipoGroupedChart.setCustomizedLegend(true);
      residuosTipoGroupedChart.setDataSource(data['chart']); 
      
      this.titles.residuosTipo = data['title'];
      residuosTipoGroupedChart.yAxisTickFormatting = this.formatNumberSeparator;
      
      //residuosTipoPieChart.setData(data['residuos']);
      //this.titles.residuosTipo = data['title'];
      let table = data['table'];
      var total = table.map(t => t.Kilos).reduce((acc, value) => acc + value, 0);
      table = this.formatDataTable(table,'Kilos');
      var residuosTipoTable = this.createTable(container,'',[],['col-lg-12']);
      residuosTipoTable.displayAllColumns = true;
      residuosTipoTable.paddingLeft = '80px';
      residuosTipoTable.paddingRight = '170px';
      residuosTipoTable.fontSize = 10;

      residuosTipoTable.setDisplayFooter(true);
      residuosTipoTable.setDataSource(table);
      
      total = this.formatNumberSeparator(total);
      residuosTipoTable.setFooters(['Total','',total]);
    }
    , error=>{ console.log(error); this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,}); })

    
}

loadResiduosTipoTratamientoChart(container = null, filters = null)
{
  
  var f = filters!=null ? filters : this.chartFilters['residuosTipoTratamiento'];
  container = container!=null ? container : this.residuosTipoTratamientoChartContainer;
  
  this.noSpeciesGroups = false;
  this.maxPlaguePresence = 0;
  
  var residuosTipoTratamientoVerticalChart = this.createStackedVerticalChart(container,'tipoResiduo','',['col-lg-12'])
 
  this.dashboard.getresiduosTipoTratamiento(f.contracts,f.desde,f.hasta,f.planta,f.tipoResiduo,f.tipoDisposicion).subscribe(
    data=>{
       
      
      residuosTipoTratamientoVerticalChart.style.legend = true;
      residuosTipoTratamientoVerticalChart.style.legendTitle = 'Planta';
      residuosTipoTratamientoVerticalChart.style.yAxisLabel = 'Kilos';
      residuosTipoTratamientoVerticalChart.style.xAxisLabel = 'Tipo disposición';
      residuosTipoTratamientoVerticalChart.style.showXAxisLabel = true;
      residuosTipoTratamientoVerticalChart.style.showYAxisLabel = true;
      
      residuosTipoTratamientoVerticalChart.title = 'Residuos gestionados';
      //residuosTipoTratamientoVerticalChart.setCustomizedLegend(true);
      residuosTipoTratamientoVerticalChart.setDataSource(data['chart']); 
      
      this.titles.residuosTipoTratamiento = data['title'];
      residuosTipoTratamientoVerticalChart.yAxisTickFormatting = this.formatNumberSeparator;
      
      //residuosTipoTratamientoPieChart.setData(data['tipoTratamiento']);
      //this.titles.residuosTipoTratamiento = data['title'];

      let table = data['table'];
      var total = table.map(t => t.Kilos).reduce((acc, value) => acc + value, 0);
      table = this.formatDataTable(table,'Kilos');
      var residuosTipoTratamientoTable = this.createTable(container,'',[],['col-lg-12']);
      residuosTipoTratamientoTable.displayAllColumns = true;
      residuosTipoTratamientoTable.paddingLeft = '80px';
      residuosTipoTratamientoTable.paddingRight = '170px';
      residuosTipoTratamientoTable.fontSize = 10;
      residuosTipoTratamientoTable.setDataSource(table);
      
      residuosTipoTratamientoTable.setDisplayFooter(true);
      total = this.formatNumberSeparator(total);
      residuosTipoTratamientoTable.setFooters(['Total','',total]);
    }
    , error=>{ console.log(error); this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,}); })
}


loadResiduosGestionadoAnioChart(container = null, filters = null)
{
  
  var f = filters!=null ? filters : this.chartFilters['residuosGestionadosAnio'];
  container = container!=null ? container : this.residuosGestionadoAnioChartContainer;
  
  this.noSpeciesGroups = false;
  this.maxPlaguePresence = 0;

  var residuosGestionAnio = this.createGroupedVerticalChart(container, 'periodo', '', ['col-lg-12']);
  
  this.dashboard.getresiduosGestionadosAnio(f.contracts,f.desde,f.hasta,f.planta,f.tipoResiduo,f.tipoDisposicion).subscribe(
    data=>{
       
  
      residuosGestionAnio.style.legend = true
      residuosGestionAnio.style.showXAxisLabel = true;
      residuosGestionAnio.style.xAxisLabel = 'Mes';
      residuosGestionAnio.style.showYAxisLabel = true;
      residuosGestionAnio.style.yAxisLabel = 'Kilos'
      residuosGestionAnio.title = 'Residuos gestionados por mes';
      //residuosGestionAnio.setCustomizedLegend(true);
      residuosGestionAnio.setDataSource(data['chart']); 
      residuosGestionAnio.yAxisTickFormatting = this.formatNumberSeparator;
      this.titles.residuosGestionadoAnio = data['title'];

      let table = data['table'];
      var total = table.map(t => t.Kilos).reduce((acc, value) => acc + value, 0);
      table = this.formatDataTable(table,'Kilos');
      
      var residuosGestionAnioTable = this.createTable(container,'',[],['col-lg-12']);
      residuosGestionAnioTable.displayAllColumns = true;
      residuosGestionAnioTable.paddingLeft = '80px';
      residuosGestionAnioTable.paddingRight = '170px';
      residuosGestionAnioTable.fontSize = 10;

      residuosGestionAnioTable.setDataSource(table);
      residuosGestionAnioTable.setDisplayFooter(true);
      total = this.formatNumberSeparator(total);
      residuosGestionAnioTable.setFooters(['Total','','',total]);
    }
    , error=>{ console.log(error); this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,}); })
  

}



loadResiduoservicios(filters = null,servicesPage){
  
  var f = filters!=null ? filters : this.chartFilters['residuosServices'];
  this.showsServicesTable = 0;
  
  this.servicesPage = servicesPage;
  var t = this.servicesOptions;
  
  this.loadingServices = 0;
  this.dashboard.getResidusoServicios(f.contracts,f.desde,f.hasta).subscribe(
    data => {
      console.log(data['datos'])
      this.residuosServicesList = data['datos'];
      //dadabikd_pesaje
      this.residuosServicesList = this.formatDataTable(this.residuosServicesList,'dadabikd_pesaje'); 
      
      this.listAllServices = new MatTableDataSource(this.residuosServicesList);    
      this.listAllServices.sort = this.allServicesSort;
      this.listAllServices.paginator = this.paginatorAllResiduos;
      this.showsServicesTable = 1;
       
      this.listAllServices.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'fecha': return new Date(item.fecha);     
          default: return item[property];
        }
      }; 

      this.loadingAllServices = 1;     
      if(this.listAllServices.length == 0){ this.listAllServices.length = 0; this.loadingServices = 2;  }     
    
      this.loadingServices = 1;     
      if(this.listServicios.length == 0){ this.loadingServices = 2;  }
      
    }, error =>{
      this.loadingServices = 3;
      this.notify.warning('Error al cargar informacion de Gestión de Residuos.');
    }
  );

  //Si se aplican filtros externos (modal) no se refrescan servicios
  if(filters!=null) return;
  
}


formatDataTable(table,column){
  for (var arrayIndex in table) { 
    table[arrayIndex][column] = this.formatNumberSeparator(table[arrayIndex][column]);
  }
  return table;
}

exportResiduosServicios(filters = null, page = null) {
    
    if(this.residuosServicesList.length==0){
      alert('No hay servicios para exportar');
      return;
    }
    const filename = 'Residuos Servicios';
    const headers = [
      'FECHA VISITA',
      'PATENTEE RETIRO',
      'CONDUCTOR',
      'N° GUIA DESPACHO',
      'FECHA INGRESO RELLENO',
      'N° INGRESO RELLENO',
      'KILOS INGRESO',
      'DISPOSICION FINAL / VALORIZACION',
      'PATENTE DISPOSICION',
      'TIPO RESIDUO',
      'NOMBRE DISPOSICION FINAL',
      'NOMBRE CLIENTE',
      'TIPO VISITA'
  ];
  let dataServices = [];
  for(let i=0;i<this.residuosServicesList.length;i++){
    
    dataServices.push({dadabikd_date:this.residuosServicesList[i]['dadabikd_date'],dadabikd_patente:this.residuosServicesList[i]['dadabikd_patente'],
      dadabikd_trabajador_disposicion:this.residuosServicesList[i]['dadabikd_trabajador_disposicion'],
      dadabikd_numero_guia_despacho:this.residuosServicesList[i]['dadabikd_numero_guia_despacho'],dadabikd_fecha_ingreso_relleno:this.residuosServicesList[i]['dadabikd_fecha_ingreso_relleno'],
      dadabikd_numero_ticket_pesajex:this.residuosServicesList[i]['dadabikd_numero_ticket_pesaje'],dadabikd_pesaje:this.residuosServicesList[i]['dadabikd_pesaje'],
      dadabikd_tipo_disposicion:this.residuosServicesList[i]['dadabikd_tipo_disposicion'],dadabikd_patente_disposicion:this.residuosServicesList[i]['dadabikd_patente_disposicion'],
      dadabikd_tipo_residuo:this.residuosServicesList[i]['dadabikd_tipo_residuo'],
      dadabikd_disposicion_final:this.residuosServicesList[i]['dadabikd_disposicion_final'],
      clie_name:this.residuosServicesList[i]['cont_name'],
      vity_name:this.residuosServicesList[i]['vity_name'],
    })
  }


  this.excelService.jsonToExcel({ headers, data: dataServices, filename });

}



  /**
   * Dada una actualizacion en el componente de filtros, actualiza las variables locales.
   * Si el cambio en los filtros ocurren en el contrato, se actualizan todos los graficos 
   * mediante updateContracts(), sino, se actualiza solo el grafico correspondiente al 
   * filtro que lanza el evento
   * @param chartGroup : string - indica el grafico o grupo de graficos que lanza el evento
   * @param event : object - indica el estado actual del filtro que lanzó el evento
   * @return none
   */
  updateFilters(chartGroup,event){
    
    this.chartFilters[chartGroup] = event.filters;
    let carga_contrato = false;
     
    if(carga_contrato){
      this.aplyFiltersToAllCharts();  
    }else{
      
      if(chartGroup=='residuosPlanta'){
        this.residuosPlantaChartContainer.clear(); 
        this.loadResiduosPlantaChart();
      }else if(chartGroup=='residuosTipo'){
        this.residuosTipoChartContainer.clear(); 
        this.loadResiduosTipoChart();
      }else if(chartGroup=='residuosTipoTratamiento'){
        this.residuosTipoTratamientoChartContainer.clear(); 
        this.loadResiduosTipoTratamientoChart();
      }else if(chartGroup=='residuosGestionadosAnio'){
        this.residuosGestionadoAnioChartContainer.clear(); 
        this.loadResiduosGestionadoAnioChart()
      }else if(chartGroup=='residuosServices'){
        //this.residuosServicesChartContainer.clear(); 
        this.loadResiduoservicios(null,1)
      }
    }
  } 
  

  updateFilterSearch(filter,search){
    
    if( filter != 'residuosPlanta' ) this.residuosPlantaFilter.filterContracts(search);
    if( filter != 'residuosTipo' ) this.residuosTipoFilter.filterContracts(search);
    if( filter != 'residuosTipoTratamiento' ) this.residuosTipoTratamientoFilter.filterContracts(search);
    if( filter != 'residuosGestionadosAnio' ) this.residuosGestionadosAnioFilter.filterContracts(search);
    if( filter != 'residuosServices' ) this.residuosServicesFilter.filterContracts(search);
  }

  isArrayEmpty( toCheck ){
    if(Array.isArray(toCheck)){
      if( toCheck.length>0 ){
        return false;
      }
    } 
    return true;
  }

    /**
   * Dada una actualizacion en el componente de filtros de tareas, actualiza las variables 
   * locales y luego actualiza la tabla mediante loadTasks()
   * @param event : object - indica el estado actual del filtro
   * @return none
   */
 



    /**
   * Establece la opcion seleccionada en todos los filtros de graficos.
   * Luego llama a la funcion aplyFiltersToAllCharts() para actualizar los resultados
   * en consecuencia con el nuevo contrato seleccionado
   * @param contracts int contrato seleccionado
   * @return none
   */
  updateContracts(contracts, type = 'SET_SELECTED' ){
    for( var key in this.chartFilters ){
      //this.chartFilters[key]['contracts'] = contracts;
    }
    
    if( type == 'SET_DEFAULT' ){
     
    }else{
     
    }
  }

  graphicGestionResiduos(){
    $('.app_menu').css({"max-height":"2700px"});
  }


  clearReferences(){
   
    this.residuosPlantaChartContainer.clear();
   
  }


  getContractNameById(contractId){
    var text = 'Ninguno';
    if( !(contractId > 0) ) return text;
    this.contratosTodos.map((c)=>{
      if( c.value == contractId ) text = c.cont_name;
    });
    return text;
  }

  // @HostListener('complete', ['$event']) 


  
  onChangeFilter(event)
  {
    if( event.eventTrigger == 'contract' ){
      this.updateContracts(event.filters.contracts);
    }
  }

  addDaysDate(date,dias){

    date = date.replaceAll("/","-");

    date = date.replaceAll("/","-");

    let  dateSplit = date.split('-')

    if(dateSplit[0].length!=4){
        date =  date.split('-').reverse().join('-');
    }

    var fecha = new Date(date+" 12:00");
    //var dias = 1; // Número de días a agregar
    fecha.setDate(fecha.getDate() + dias);

    let year =fecha.getFullYear();
    let day = fecha.getDate();
    let month = fecha.getMonth()+1;
    let dayStr = ''+day;
    let monthStr = ''+month;

    if(day<10) dayStr='0'+day;
    if(month<10)monthStr='0'+month;

    return  year+"-"+monthStr+"-"+dayStr;
}

}