import { Component, OnInit,ViewChild, HostBinding, EventEmitter, Output, HostListener } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material'; 
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: "data-table",
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
// [class='col-lg-6 col-md-12']
export class DataTableComponent implements OnInit {
  public title = "";
  public dataSource : any = [];
  public isLoading = true;

  public index : number;
  public selfRef: DataTableComponent;
  public headers : any[];
  public tableFooterColumns: string[] = ['Kilos'];
  public footers: any[];
  public displayedColumns : any[];
  public fixedColumnWidth : number = 0;
  public columnReferences : any = [];
  public pageSize : number = 5;
  public fontSize : number;
  public tableClasses : string = '';
  public cellPaddingLeft : number = 20;
  public textAlign : string = 'center';
  public displayAllColumns = false;
  public showNone : boolean = false;
  public showPaginator : boolean = true;
  public customTableWidth = false;
  customTag :string[] = [];
  customTagPosition :string = 'middle';
  displayFrame = true;
  displayFooter = false;
  
  public columnSizes = {};
  
  isHoverEnabled = false;
  hoverColor = null;
  hoverTextColor = null;
  customRowTitle = null;
  
  @HostBinding('class.col-md-4') isColMd4 : boolean;
  @HostBinding('class.col-lg-4') isColLg4 : boolean;
  @HostBinding('class.col-lg-6') isColLg6 : boolean;
  @HostBinding('class.col-md-6') isColMd6 : boolean;
  @HostBinding('class.col-md-8') isColMd8 : boolean;
  @HostBinding('class.col-lg-8') isColLg8 : boolean;
  @HostBinding('class.col-lg-12') isColLg12 : boolean;
  @HostBinding('class.col-md-12') isColMd12 : boolean;

  @HostBinding('style.padding-left') paddingLeft : string;
  @HostBinding('style.padding-right') paddingRight : string;
  // @HostBinding()

  @ViewChild('sort', {read: MatSort}) sort: MatSort;
  @ViewChild('paginator', {read: MatPaginator}) paginator: MatPaginator;

  @Output('clickOnCell') clickOnCell = new EventEmitter();

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    // this.size = '0 0 50%';
    
  }

  // @HostListener('mouseover',['$event']) onHover(event){
  //     if(event.target.classList.contains('svgAssetMarker')){
  //         console.log('mouseenter',event)
  //     }
  // }

  // @HostListener('mouseout',['$event']) onBlur(event){
  //     if(event.target.classList.contains('svgAssetMarker')){
  //         console.log('mouseleave',event)
  //     }
  // }

  setHeaders(headers: any[] ){
    this.headers = headers;
  }
  setFooters(footers: any[] ){
    this.footers = footers;
  }
  setDisplayFooter(display){
    this.displayFooter = display;
  }

  setClasses(classes){
    if( !Array.isArray(classes) ) return false;
    if(classes.includes('col-lg-4')) this.isColLg4 = true;
    if(classes.includes('col-md-4')) this.isColMd4 = true;
    if(classes.includes('col-lg-6')) this.isColLg6 = true;
    if(classes.includes('col-md-6')) this.isColMd6 = true;
    if(classes.includes('col-lg-8')) this.isColLg8 = true;
    if(classes.includes('col-md-8')) this.isColMd8 = true;
    if(classes.includes('col-lg-12')) this.isColLg12 = true;
    if(classes.includes('col-md-12')) this.isColMd12 = true;
  }

  setDataSource(dataSource){
    // console.log('settingDataSource',dataSource);
    if( !Array.isArray(dataSource) ) return false;
    if(dataSource.length>0){
      this.showNone = false;
      if(this.displayAllColumns){
        var columns = [];
        Object.keys(dataSource[0]).map((v)=>{
          columns.push(v);
        });
        this.headers = columns;

      }
    }else{
      this.showNone = true;
    }
    this.isLoading = false;
    this.dataSource = new MatTableDataSource(dataSource);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  setColumnReference(columnReferences:[]){
    this.columnReferences = columnReferences;
  }

  hasColumnReference(header){
    if(header in this.columnReferences){
      return true;
    }
    return null;
  }
  
  hasCustomSize(header){
    if(header in this.columnSizes){
      return true;
    }
    return null;
  }

  dontPaginate(){
    this.showPaginator = false; //hide paginator html
    this.pageSize = 999999; //pagination virtually infinite
  }

  setColumnSizes(columnSizes){
    this.columnSizes = columnSizes;
  }

  setCustomTag(tag:string[]=[]){
    this.customTag = tag;
  }

  setCustomTagPosition(position :string = 'middle'){
    this.customTagPosition = position;
  }

  showAllColumns(){
    this.displayAllColumns = true;
  }

  onClickOnCell(column, row, index){
    let eventData = {
      column : column
      , rowIndex : index
      , row : row
      , value : row[column]
    }
    this.clickOnCell.emit(eventData);
  }

  getColor(index){
    var color = { row : 'white', text: 'black' };
    if(this.isHoverEnabled){
      if(this.hoveredRow == index){
        color.row =  this.hoverColor!=null ? this.hoverColor : '#11111111';
        color.text = this.hoverTextColor!=null ? this.hoverTextColor : 'black';
      }
    }
    return color;
  }

  setHoverColors(background,text)
  {
    this.hoverColor = background;
    this.hoverTextColor = text;
  }

  getCursor(index){
    var cursor = '';
    if(this.isHoverEnabled){
      if(this.hoveredRow == index){
        cursor =  'pointer';
      }
    }
    return cursor;
  }

  hoveredRow = null;

  onHover(index,type){
    if(type=='in'){
      this.hoveredRow = index;
    }else{
      this.hoveredRow = null;
    }
  }

  showFrame()
  {
    this.displayFrame = true;
  }

  hideFrame()
  {
    this.displayFrame = false;
  }

  setCustomTableWidth(width)
  {
      this.customTableWidth = width;
  }
  
}
