import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ContractDocsService {
  private baseUrl: string;
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      }),
    };
  }

  // obtener los documentos asociados a un contrato.
  getDocumentsByContractId(contractId, segtyId, docsPerms): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "contracts/getDocs",
      {
        contId: contractId,
        segtyId,
        docsPerms,
      },
      this.httpOptions
    );
  }

  updateDocumentName(
    documentId,
    newName,
    enable,
    reposActualName,
    reposRoute,
    userId,
    resutyId
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "contracts/updateRepo",
      {
        reposId: documentId,
        newName,
        enable,
        reposActualName,
        reposRoute,
        userId,
        resutyId,
      },
      this.httpOptions
    );
  }

  deleteDocument(documentId): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "contracts/deleteDoc",
      {
        id: documentId,
      },
      this.httpOptions
    );
  }

  uploadDocument(form: FormData): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "contracts/uploadDoc",
      form,
      {
        headers: new HttpHeaders({
          Authorization: "Bearer " + sessionStorage.getItem("token")
        }),
      }
    );
  }

  overrideDocumentS3(form: FormData): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "contracts/overrideDocS3",
      form,
      
      {
        headers: new HttpHeaders({
          Authorization: "Bearer " + sessionStorage.getItem("token")
        }),
      }
    );
  }

  getDocumentTypes(compId): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "contracts/getDocTypes",
      {
        compId,
      },
      this.httpOptions
    );
  }

  getDocumentSubTypes(docTypeId): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "contracts/getDocSubTypes",
      {
        retyId: docTypeId,
      },
      this.httpOptions
    );
  }
}
