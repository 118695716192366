import { Component, OnInit,ViewContainerRef, ViewChild, AfterViewInit, ElementRef, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormControlDirective } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
//import { Task } from './task.model';
//import { TaskModalService } from 'src/app/services/task-modal.service';

@Component({
  selector: 'app-view-image-modal',
  templateUrl: './view-image-modal.component.html',
  styleUrls: ['./view-image-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/*
  Esta clase aplica solo para comentarios sobre el grafico "Saturacion TUV Semanal por Plaga"
  ya que es el unico, hasta el momento, que requiere de inyeccion dinamica de componentes 
  (ViewContainerRef).
*/
export class ViewImageModalComponent implements OnInit,AfterViewInit {
  public saving = false; 
  private _d :any;

  //se utiliza esta clase para manejar los datos del formulario
  //private task: Task;

  private task = {'anio':''} as any;

  @Output() complete = new EventEmitter();
  @Output() edit = new EventEmitter();

  @ViewChild("container", {read: ViewContainerRef}) container: ViewContainerRef;
  @ViewChild("bodyContainer", {read: ElementRef}) bodyContainer: ElementRef;

  public chartRefSubject: BehaviorSubject<ViewContainerRef> = new BehaviorSubject<ViewContainerRef>(null);
  public bodyRefSubject: BehaviorSubject<ElementRef> = new BehaviorSubject<ElementRef>(null);
  
  public completeForm: FormGroup
  public type: string;
  //private service : TaskModalService;
  public loading = true;
  public taskType;
  public dataImage = [];
  public totalConsumption = 0;
  public totalImage = 0;
  public urlS3;
  public clieId;
  

  constructor(
    public bsModalRef: BsModalRef,
    public vcRef: ViewContainerRef,
    private fBuilder : FormBuilder,
    private detector: ChangeDetectorRef
  ) { 
    
  }

 


  ngOnInit() {
    
    this.urlS3 = environment.s3Url;
    this.clieId = JSON.parse(sessionStorage.getItem('userData')).clie_id;
    this.urlS3+=this.clieId+'/uploads/';
    
  }

  
  setData(data){
    this.dataImage = data;
    this.totalImage = data.length;
  }
  setLoading(value){
    this.loading = value;
  }

  setBody(body : ElementRef){
    this.bodyContainer = body;
  }

  setCallback(){

  }

  

  ngAfterViewInit() {
    this.chartRefSubject.next(this.container);
    this.refresh();
  }

  refresh(){
    this.detector.detectChanges();
  }

  onCancelClicked() {
    this.bsModalRef.hide()
  }

  changeField(){
  }

  
  updateCount(event){
    var elm = event.target;
    var restante = elm.maxLength - elm.value.length;
    if(restante>0 && restante != elm.maxLength)   $("#"+elm.id).css("border-left", "5px solid #42A948");
    else $("#"+elm.id).css("border-left", "5px solid #a94442");
    $('.task-'+elm.id).text(restante);
  }

  setType(type:string){
    this.type = type;
    this.refresh();
  }

  setTaskType(taskType){
    this.taskType = taskType;
    this.refresh();
  }

  

  //funcion que actualiza valores de captura y consumo de dispositivos
  //al ingresar comentarios para facilitar la operacion del usuario
 

}
