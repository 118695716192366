import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { AssetMarkerComponent } from "src/app/components/leaflet/asset-marker/asset-marker.component";

//TODO AGREGAR OPCIONES HEADERS
@Injectable({
  providedIn: "root",
})
export class AssetsMapService {
  private baseUrl: string;
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      }),
    };
  }

  getAssets(): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "assets/get",
      {},
      this.httpOptions
    );
  }

  getWorkersTracks(): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "workers/tracks/get",
      {},
      this.httpOptions
    );
  }

  getVehiclesTracks(): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "vehicles/tracks/get",
      {},
      this.httpOptions
    );
  }

  getProperties(): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "properties/get",
      {},
      this.httpOptions
    );
  }

  getAssetServices(assetId): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "assets/getServices",
      { id: assetId },
      this.httpOptions
    );
  }

  getAllAssetServices(assets): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "assets/services/all",
      { assets: assets },
      this.httpOptions
    );
  }

  exportAssetServices(services, filters): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "assets/services/export",
      { services: services, filters: filters },
      this.httpOptions
    );
  }

  getUpdatedTrackings(): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "trackings/refresh",
      {},
      this.httpOptions
    );
  }

  getUpdatedAssets(
    markers: AssetMarkerComponent[],
    date: string = ""
  ): Observable<any[]> {
    var assets = [];
    markers.forEach((m) => {
      assets.push(m.getAsset().id);
    });
    console.log("AssetsMapService::getUpdatedAssets():assets", assets);
    return this.http.post<any[]>(
      this.baseUrl + "assets/refresh",
      { assets: assets, date: date },
      this.httpOptions
    );
  }
}
