import { Injector } from '@angular/core';

// import { BaseControlComponent } from '../components/common/controls/base-control/base-control.component';
// import { }


export let AppInjector: Injector;
export function setAppInjector(injector: Injector) {
    if (AppInjector) {
        // Should not happen
        console.error('Programming error: AppInjector was already set');
    }
    else {
        AppInjector = injector;
    }
}
