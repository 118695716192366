import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ContractPlanService {
  private baseUrl: string;
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      }),
    };
  }

  getAssets(contract, serviceGroupType): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "assets/getByContract",
      {
        contract: contract,
        serviceGroupType: serviceGroupType,
      },
      this.httpOptions
    );
  }

  getAssetInfo(assetId) {
    return this.http.post<any[]>(
      this.baseUrl + "assets/getInfo",
      {
        assetId: assetId,
      },
      this.httpOptions
    );
  }

  getPlans(contract, serviceGroupType) {
    return this.http.post<any[]>(
      this.baseUrl + "contracts/getPlans",
      {
        contract: contract,
        serviceGroupType: serviceGroupType,
      },
      this.httpOptions
    );
  }

  getPlanSvg(blueprint) {
    return this.http.post<any[]>(
      this.baseUrl + "contracts/getPlanSvg",
      {
        blueprint: blueprint,
      },
      this.httpOptions
    );
  }

  getPlanInfo(blueprint) {
    return this.http.post<any[]>(
      this.baseUrl + "contracts/getPlanInfo",
      {
        blueprint: blueprint,
      },
      this.httpOptions
    );
  }

  getPlanPdfUrl(blueprint) {
    return this.http.post<any[]>(
      this.baseUrl + "plan/getPdfUrl",
      {
        blueprint: blueprint,
      },
      this.httpOptions
    );
  }

  savePlanPdf(pdf, blueprint) {
    return this.http.post<any[]>(
      this.baseUrl + "plan/putPdf",
      {
        blueprint: blueprint,
        pdf: pdf,
      },
      this.httpOptions
    );
  }
}
