import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { report } from "./report";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class RepositoriesService {
  private baseUrl: string;
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      }),
    };
  }

  getAllDoc(
    modulo,
    arg,
    t1 = null,
    t2 = null,
    cont_id = "-1",
    cust_id = "-1",
    timezone
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "reporte_all",
      {
        modulo: modulo,
        cust_id: cust_id,
        cont_id: cont_id,
        comp: arg,
        t1: t1,
        t2: t2,
        timezone: timezone,
      },
      this.httpOptions
    );
  }

  postUrl(arg, arg1, arg2, arg3, arg5 = null): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "archivo_U",
      {
        url: arg,
        name: arg1,
        type: arg2,
        compid: arg3,
        work_code: arg5,
      },
      this.httpOptions
    );
  }
  postDesc(arg, arg2): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "archivo_D",
      { id: arg2, desc: arg },
      this.httpOptions
    );
  }

  postFile(fileToUpload: FormData): Observable<any> {
    const endpoint = this.baseUrl + "archivo";
    const formData: FormData = new FormData();

    return this.http.post<any>(endpoint, fileToUpload, {
      headers: new HttpHeaders({
        Authorization: "Bearer " + sessionStorage.getItem("token")
      }),
    });
  }

  setId(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + "sesion", this.httpOptions);
  }
  getId(): Observable<report[]> {
    return this.http.get<report[]>(this.baseUrl + "reporte", this.httpOptions);
  }
  getList(
    modulo,
    tipo,
    subtipo,
    comp,
    t1,
    t2,
    cont_id = "-1",
    code = null,
    name = null,
    cust_id = "-1",
    timezone
  ): Observable<any> {
    console.log(cont_id);
    return this.http.post<any>(
      this.baseUrl + "reporte_G",
      {
        modulo: modulo,
        cust_id: cust_id,
        cont_id: cont_id,
        type: tipo,
        subtype: subtipo,
        comp: comp,
        t1: t1,
        t2: t2,
        work_code: code,
        work_name: name,
        timezone: timezone,
      },
      this.httpOptions
    );
  }
  getType(modulo): Observable<any> {
    let a = this.http.post<any>(
      this.baseUrl + "reporte_T",
      {
        modulo: modulo,
        clie_id: JSON.parse(sessionStorage.getItem("userData")).clie_id,
      },
      this.httpOptions
    );
    return a;
  }
  getSubType(rety_id): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "reporte_subtype",
      { rety_id: rety_id },
      this.httpOptions
    );
  }

  postContid(cont_id, id): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "archivo_C",
      { id: id, cont_id: cont_id },
      this.httpOptions
    );
  }

  delete_repositories(repos_id, repos_route): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "delete_repositories",
      {
        repos_id: repos_id,
        repos_route: repos_route,
      },
      this.httpOptions
    );
  }

  disable_repositories(repos_id): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "disable_repositories",
      { repos_id: repos_id },
      this.httpOptions
    );
  }
}
