import { Component, OnInit, ViewChild } from '@angular/core';
import { AssetsMapComponent } from '../assets-map/assets-map.component';
import { DataTableComponent } from '../dashboard/data-table/data-table.component';
import { formGroupNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
import { AssetsMapService } from 'src/app/services/assets-map.service';
import { FormsService } from 'src/app/services/forms.service';
import { AssetsFilterComponent } from 'src/app/components/assets/assets-filter/assets-filter.component';
import { SnotifyService } from 'ng-snotify';
import { saveAs } from 'file-saver/';


@Component({
    selector: 'app-assets',
    templateUrl: './assets.component.html',
    styleUrls: ['./assets.component.css']
})


export class AssetsComponent implements OnInit {
    
    // @ViewChild('assetsTable', {read:DataTableComponent} ) assetsTable :DataTableComponent;
    @ViewChild('servicesTable', {read:DataTableComponent} ) servicesTable :DataTableComponent;
    @ViewChild('filter', {read:AssetsFilterComponent} ) filter :AssetsFilterComponent;

    
    displayDetail = false;
    displayMap = true;
    storedServices = [];
    loadingServices = false;
    // untouched = true;
    serviceData = [];
    serviceDataLoaded = false;
    assets = [];
    services = [];
    filters = [];
    displayedServices = [];
    // modalTitle = 'Cargando...';

    constructor(private assetHTTP :AssetsMapService, private formService :FormsService, private notify: SnotifyService) { }

    ngOnInit() {
        this.servicesTable.isHoverEnabled = true;
        this.servicesTable.setHeaders(['frecuencia','propiedad','subpropiedad',
        'cliente_actual','contrato_actual','codigo_articulo','fecha_realizacion','hora_realizacion',
        'servicio','estado_realizacion','razon_no_realizacion','contrato_servicio','técnico','vehículo']);
        this.servicesTable.setCustomTableWidth('1500px');
        this.servicesTable.fontSize = 10;
        this.servicesTable.pageSize = 10;
        // this.assetsTable.title = 'Articulos';
        // this.assetsTable.setHeaders(['articulo','estado']);
        // this.assetsTable.isHoverEnabled = true;
        // this.filter.initFilters([]);
    }   

    // onAssetClicked(event)
    // {
    //     // this.untouched = false;
    //     var asset = event.row;
    //     var services = this.assetAlreadyLoaded(asset.id);
    //     if (services != null)
    //     {
    //         this.loadServicesInTable(services,asset.articulo);
    //     } else {
    //         this.serviceTableLoading();
    //         this.assetHTTP.getAllAssetServices(event.row.id).subscribe(
    //             data => {
    //                 asset.services = data['services'];
    //                 this.storedServices.push(asset);
    //                 this.loadServicesInTable(data['services'],asset.articulo);
    //                 // this.loadingServices = false;
    //             }
    //         )
    //     }
    // }

    serviceTableLoading()
    {
        this.servicesTable.setDataSource([]);
        this.servicesTable.isLoading = true;
        this.servicesTable.title = 'Cargando...';
    }

    onServiceClicked (event)
    {
        this.serviceData = [];
        this.serviceDataLoaded = false;

        $("#serviceModal").find('.modal-title').html('Servicio: <strong>'+event.row.servicio + ' - ' +event.row.fecha_realizacion + '</strong>')
        $("#serviceModal").show();

        this.formService.getFormByServiceAssetId(event.row.id).subscribe(
            data => {
                this.serviceDataLoaded = true;
                this.serviceData = data['controls'];
            }
        )
    }

    closeModal()
    {
        $("#serviceModal").hide();
    }

    processAssets(data)
    {
        this.assets = data['assets'];
        // this.addAssetsToTable(data['assets']);
        var ids = data['assets'].map(x => x['id']);
        this.assetHTTP.getAllAssetServices(ids).subscribe(
            data => {
                this.services = data['services'];
                this.displayedServices = this.services;
                this.servicesTable.setDataSource(data['services']);
                this.servicesTable.isLoading = false;

                // asset.services = data['services'];
                // this.storedServices.push(asset);
                // this.loadServicesInTable(data['services'],asset.articulo);
            }
        )
        this.filter.initFilters(data['assets']);
    }

    addAssetsToTable (newAssets)
    {
        var assets = [];
        newAssets.forEach(asset => {
            assets.push({
                'id' : asset.id,
                'articulo' : asset.name,
                'estado': asset.status
            });
        })
        // this.assetsTable.setDataSource(assets);
    }

    showDetail()
    {
        this.displayMap = false;
        this.displayDetail = true;
    }

    showMap() 
    {
        this.displayDetail = false;
        this.displayMap = true;
    }

    assetAlreadyLoaded(id)
    {
        var services = null;
        this.storedServices.forEach( asset =>{
            if(asset.id == id){
                services = asset.services;
            }
        });
        return services;
    }

    // loadServicesInTable(services,assetName)
    // {
    //     var tableData = [];
    //     services.forEach(service=>{
    //         tableData.push({
    //             'id' : service.id,
    //             'fecha' : service.date,
    //             'estado' : service.status,
    //             'nombre' :service.name
    //         })
    //     })
    //     this.servicesTable.title = 'Historial de servicios para el articulo ' + assetName;
    //     this.servicesTable.setDataSource(tableData);
    //     this.servicesTable.isLoading = false;
    // }

    applyFilter(event)
    {
        this.filters = event;
        if (event.startDate!="" && event.endDate!="") {
            if (event.startDate > event.endDate) {
                this.notify.info('La fecha de termino no debe ser menor a la de inicio',{timeout:5000});
                return;
            }
        }
        var displayedServices = this.services;
        if (event.contracts.length>0){
            displayedServices = displayedServices.filter(s => {
                
                var contracts = event.contracts.map( c => c.split(' - ')[1].trim().toLowerCase() );
                
                return contracts.includes(s.contrato_actual.toLowerCase()) || contracts.includes(s.contrato_servicio.toLowerCase());
            });
        }
        if (event.clients.length>0){
            displayedServices = displayedServices.filter(s => event.clients.includes(s.cliente_actual));
        }
        if (event.properties.length>0){
            displayedServices = displayedServices.filter(s => event.properties.includes(s.propiedad));
        }
        if (event.code!="") {
            displayedServices = displayedServices.filter(s => s.codigo_articulo.toLowerCase().includes(event.code.toLowerCase()));
        }
        if (event.startDate!="") {
            displayedServices = displayedServices.filter(s => this.compareDates(`${s.fecha_realizacion} ${s.hora_realizacion}`, event.startDate + ' 00:00:00','>='));
        }
        if (event.endDate!="") {
            displayedServices = displayedServices.filter(s => this.compareDates(`${s.fecha_realizacion} ${s.hora_realizacion}`, event.endDate + ' 23:59:59','<='));
        }

        this.displayedServices = displayedServices;
        this.servicesTable.setDataSource(displayedServices);
    }
    
    JSONparse(json)
    {
        return JSON.parse(json);
    }

    compareDates(fullDate, filterDate, operator = '=') :boolean
    {
        var dateTime = fullDate.split(" ");
        var flippedDate = dateTime[0].split('-').reverse().join('-') + ' ' + dateTime[1];
        switch(operator){
            case '=':  return flippedDate == filterDate;
            case '>=': return flippedDate >= filterDate;
            case '<=': return flippedDate <= filterDate;
            default  : return false;
        }
    }

    excelExport()
    {
        this.assetHTTP.exportAssetServices(this.displayedServices,this.filters).subscribe(
            data =>  {
                if (data['status']) {
                    saveAs(data['link'], "servicios.xlsx");
                } else {
                    this.notify.error(data['error'],{ timeout:10000, pauseOnHover:true });
                }
            }
        )
        this.notify.info('Estamos generando su archivo Excel...',{timeout:10000});
    }
}
