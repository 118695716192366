import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FormsService {
  private baseUrl: string;
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    };
  }

  getFormByServiceAssetId(id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "forms/getByServiceAsset",
      {
        servicesAssetsId: id,
      },
      this.httpOptions
    );
  }
}
