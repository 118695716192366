import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class VisitasService {
  private baseUrl: string;
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      }),
    };
  }

  get_activities(
    clie_id,
    cont_id,
    segty_id,
    timezone,
    acti_create
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_activities",
      {
        clie_id: clie_id,
        cont_id: cont_id,
        segty_id: segty_id,
        timezone: timezone,
        acti_create: acti_create,
      },
      this.httpOptions
    );
  }

  acti_filter(
    clie_id,
    acti_status,
    fechaINI,
    fechaFIN,
    cont_id,
    segty_id,
    timezone,
    acti_create
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "actiFilters",
      {
        clie_id: clie_id,
        acti_status: acti_status,
        fechaINI: fechaINI,
        fechaFIN: fechaFIN,
        cont_id: cont_id,
        segty_id: segty_id,
        timezone: timezone,
        acti_create: acti_create,
      },
      this.httpOptions
    );
  }

  get_visits_s3(id): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "get_visits_s3",
      {
        id: id,
      },
      this.httpOptions
    );
  }

  getTypeReport(): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "list_rety",
      {
        clie_id: JSON.parse(sessionStorage.getItem("userData")).clie_id,
      },
      this.httpOptions
    );
  }

  getSubType(rety_id): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "reporte_subtype",
      { rety_id: rety_id },
      this.httpOptions
    );
  }

  // subida de archivos s3
  postUrl(arg, arg1, arg2, arg3, arg5 = null): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "archivo_U",
      {
        url: arg,
        name: arg1,
        type: arg2,
        compid: arg3,
        work_code: arg5,
      },
      this.httpOptions
    );
  }

  postFile(fileToUpload: FormData): Observable<any> {
    const endpoint = this.baseUrl + "archivo";
    const formData: FormData = new FormData();

    return this.http.post<any>(endpoint, fileToUpload, {
      headers: new HttpHeaders({
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      }),
    });
  }

  get_profiles_options(user_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_profiles_options",
      {
        user_id: user_id,
      },
      this.httpOptions
    );
  }
}
