import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DocumentosEmpresasService {
  private baseUrl: string;
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    };
  }

  get_emp(
    repos_table,
    repos_table_id,
    comp_id,
    t1 = null,
    t2 = null,
    timezone
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_docEmp",
      {
        repos_table: repos_table,
        repos_table_id: repos_table_id,
        comp_id: comp_id,
        t1: t1,
        t2: t2,
        timezone: timezone,
      },
      this.httpOptions
    );
  }

  getListFilter(
    repos_table,
    repos_table_id,
    tipo,
    subtipo,
    comp_id,
    t1,
    t2,
    timezone
  ): Observable<number> {
    return this.http.post<number>(
      this.baseUrl + "get_docEmpFilter",
      {
        repos_table: repos_table,
        repos_table_id: repos_table_id,
        type: tipo,
        subtype: subtipo,
        comp_id: comp_id,
        t1: t1,
        t2: t2,
        timezone: timezone,
      },
      this.httpOptions
    );
  }
}
