import {
  Injectable,
  TemplateRef,
  ViewContainerRef,
  ComponentFactory,
  ComponentFactoryResolver,
} from "@angular/core";
import { Control } from "src/app/models/control.model";
import { AppInjector } from "src/app/services/app-injector.service";
import { TextControl } from "src/app/components/common/controls/text/text.component";
import { SelectControl } from "src/app/components/common/controls/select/select.component";
import { CheckboxControl } from "src/app/components/common/controls/checkbox/checkbox.component";
import { DateControl } from "../components/common/controls/date/date.component";
import { SearchControl } from "../components/common/controls/search/search.component";

@Injectable({
  providedIn: "root",
})
export class ControlFactoryService {
  protected resolver: ComponentFactoryResolver;
  protected supportedControls = {
    TEXT: TextControl,
    SELECT: SelectControl,
    MULTISELECT: SelectControl,
    CHECKBOX: CheckboxControl,
    DATE: DateControl,
    SEARCH: SearchControl,
  };

  constructor() {
    this.resolver = AppInjector.get(ComponentFactoryResolver);
  }

  createControl(schema: Control) {
    var control = null;
    if (this.supportedControls.hasOwnProperty(schema.type)) {
      control = new this.supportedControls[schema.type]();
    }
    return control;
  }

  insertControl(container: ViewContainerRef, schema: Control) {
    var newControl: any = null;
    let controlFactory = this.resolver.resolveComponentFactory(
      this.supportedControls[schema.type]
    );
    if (this.supportedControls.hasOwnProperty(schema.type)) {
      let controlReference = container.createComponent(controlFactory);
      newControl = controlReference.instance;
      newControl.setData(schema);
    }

    return newControl;
  }
}
