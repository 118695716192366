import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class JarwisService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
  }

  login(data) {
    return this.http.post(this.baseUrl + 'login', data);
  }

  sendPasswordResetLink(data) {
    return this.http.post(this.baseUrl + 'sendPasswordResetLink', data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    });
  }

  cambiarPassword(data) {
    return this.http.post( this.baseUrl + 'resetPassword', data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    });
  }

  modificaPassword(data) {
    return this.http.post(this.baseUrl + 'modificaPassword', data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    });
  }

  obtenerDatosMenu(data) {
    return this.http.post(this.baseUrl + 'datosMenu', data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    });
  }

  obtenerContratos(data) {
    return this.http.post(this.baseUrl + 'contratos', data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    });
  }

  obtenerPermisos(data) {
    return this.http.post(this.baseUrl + 'permisos', data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    });
  }

  obtenerDatosLineaNegocio(data) {
    return this.http.post(this.baseUrl + 'datosLineaNegocio', data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    });
  }

  obtenerSubmenuLineaNegocio(data) {
    return this.http.post(this.baseUrl + 'subMenuLineaNegocio', data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    });
  }
}
