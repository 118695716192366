import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ClientsOptionsService {
  private baseUrl: string;
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    };
  }

  get_options(clie_id): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + "get_clients_opt", {
      clie_id: clie_id
    },
    this.httpOptions);
  }
}
