import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  private baseUrl: string;
  private httpOptions: Object;

  private iss = {
    login: environment.apiUrl + "/login",
  };

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    };
  }

  handle(token) {
    this.set(token);
  }

  set(token) {
    sessionStorage.setItem("token", token);
  }

  get() {
    return sessionStorage.getItem("token");
  }

  remove() {
    sessionStorage.removeItem("token");
  }
  verify(): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "verify",
      { token: sessionStorage.getItem("token") },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        }),
      }
    );
  }

  isValid() {
    const token = this.get();

    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;
      }
    }
    return false;
  }

  payload(token) {
    const payload = token.split(".")[1];
    return this.decode(payload);
  }

  decode(payload) {
    return JSON.parse(atob(payload));
  }

  loggedIn() {
    if (sessionStorage.getItem("auth") == "1") {
      return true;
    } else {
      return false;
    }
    return this.isValid();
  }
}
