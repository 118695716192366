import { Component, OnInit, Input, Output, HostBinding } from '@angular/core';
import { EventEmitter } from 'events';
import { ChartInterface } from 'src/app/interfaces/chart-interface';
import {MatCardModule} from '@angular/material/card';
import { trigger } from '@angular/animations';

@Component({
  selector: 'vertical-chart',
  templateUrl: './vertical-chart.component.html',
  styleUrls: ['./vertical-chart.component.css']
})
export class VerticalChartComponent implements OnInit {

  public title = "";
  public dataSource : any = null;

  public index : number;
  public selfRef: VerticalChartComponent;
  public isLoading = true;
  public legend = "";
  public type : string = '';
  public style : any;

  public chartStyles = {
    'plaga' : {
      view : [510, 240]
      , gradient : false
      , xAxis : true
      , yAxis : true
      , scheme : {domain: ['#3211AB', '#E51807','#9210F7','#28930B','##E08E00']}
      , barPadding : 1
      , groupPadding : 1
      , yScaleMax : 1
      , legend :  true
      , legedTitle : 'Año'
      , showXAxisLabel : true
      , showYAxisLabel : true
      , yAxisLabel : 'Cantidad de locales'
      , rotateXAxisTicks : false
      , showDataLabel: true
      // , yAxisTickFormatting : 'yAxisTickFormatting'
    }
    , 'auditoria' : {
      view : [500, 350]
      , gradient : false
      , xAxis : true
      , yAxis : true
      , scheme : {domain: ['#3211AB']}
      , barPadding : 1
      , groupPadding : 6
      , yScaleMax : 100
      , legend :  true
      , legedTitle : ''
      , showXAxisLabel : false
      , showYAxisLabel : true
      , yAxisLabel : 'Nota (%)'
      , rotateXAxisTicks : false
      , showDataLabel: false
      // , yAxisTickFormatting :'yAxisTickFormatting'
    },
    'tipoDisposicion' : {
      view : [1030, 400]
      , gradient : false
      , xAxis : true
      , yAxis : true
      , scheme : {domain: ['#7859EB','#FB886F','#F7FB7B','#78D35F','#C98ECF','#7FD9F7','#F2B25F','#B2EA70']}
      , barPadding : 1
      , groupPadding : 6
      , yScaleMax : 100
      , legend :  true
      , legedTitle : ''
      , showXAxisLabel : false
      , showYAxisLabel : true
      , yAxisLabel : ''
      , rotateXAxisTicks : false
      , showDataLabel: false
      , yAxisTickFormatting : 'yAxisTickFormatting'
      , xScaleMax : 1
    },
    
  };

  
 
  @HostBinding('class.col-lg-6') isColLg6 : boolean;
  @HostBinding('class.col-md-6') isColMd6 : boolean;
  @HostBinding('class.col-lg-12') isColLg12 : boolean;
  @HostBinding('class.col-md-12') isColMd12 : boolean;
  

  //interface for Parent-Child interaction
  public parentReference : ChartInterface;
  isDataValid = true;
  customTag :string[] = [];
  customTagPosition :string = 'middle';


  constructor() {}

  setClasses(classes){
    if( !Array.isArray(classes) ) return false;
    if(classes.includes('col-lg-6')) this.isColLg6 = true;
    if(classes.includes('col-md-6')) this.isColMd6 = true;
    if(classes.includes('col-lg-12')) this.isColLg12 = true;
    if(classes.includes('col-md-12')) this.isColMd12 = true;
  }

  setType(type){
    this.type = type;
    this.style = this.chartStyles[type];
  }
  

  yAxisTickFormatting = (value: number) => {
    if (Math.floor(value) !== value) {
      return '';
    }
    return value;
  }

  // @Input('type') type : string; 
  // @Output() onLoad = new EventEmitter();


  ngOnInit() {
  }

  setDataSource(dataSource){
    this.isLoading = false;
    this.dataSource = dataSource;
    this.validateData();
  }

  validateData(){
    var data = this.dataSource;
    var barCount = 0;
    data.map((group)=>{
      group.series.map((bar)=>{
          if(bar.value>0) barCount++;
      });
    });
    this.isDataValid = barCount>0;
  }

  setCustomTag(tag:string[]=[]){
    this.customTag = tag;
  }

  setCustomTagPosition(position :string = 'middle'){
    this.customTagPosition = position;
  }

}

