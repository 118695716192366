import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class RecommendationService {
  private baseUrl: string;
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    };
  }

  getServices(clieId): Observable<any[]> {
    var options =
      typeof sessionStorage.getItem("options") == null
        ? []
        : JSON.parse(sessionStorage.getItem("options"));
    return this.http.post<any[]>(
      this.baseUrl + "recommendation/getServices",
      {
        clieId: clieId,
        forms: options["forms_recomendations"],
      },
      this.httpOptions
    );
  }

  getFilters(clieId, segtyId): Observable<any[]> {
    var options =
      typeof sessionStorage.getItem("options") == null
        ? []
        : JSON.parse(sessionStorage.getItem("options"));
    return this.http.post<any[]>(
      this.baseUrl + "recommendation/getFilters",
      {
        clieId: clieId,
        segtyId: segtyId,
        forms: options["forms_recomendations"],
      },
      this.httpOptions
    );
  }

  //obtiene estructura de inputs y valores (si es que se le proporciona id) de una recomendacion.
  getFilteredRecommendation(filter, segtyId, clieId): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "recommendation/getFilteredRecommendations",
      {
        segtyId: segtyId,
        clieId: clieId,
        filter: filter,
      },
      this.httpOptions
    );
  }

  getContractRecommendations(
    filter,
    clieId,
    segtyId,
    contractId,
    page
  ): Observable<any[]> {
    var options =
      typeof sessionStorage.getItem("options") == null
        ? []
        : JSON.parse(sessionStorage.getItem("options"));
    return this.http.post<any[]>(
      this.baseUrl + "recommendation/getContractRecommendations",
      {
        filter: filter,
        clieId: clieId,
        segtyId: segtyId,
        contId: contractId,
        forms: options["forms_recomendations"],
      },
      this.httpOptions
    );
  }

  exportContractRecommendations(
    filter,
    clieId,
    segtyId,
    contractId,
    page
  ): Observable<any[]> {
    var options =
      typeof sessionStorage.getItem("options") == null
        ? []
        : JSON.parse(sessionStorage.getItem("options"));
    return this.http.post<any[]>(
      this.baseUrl + "recommendation/export",
      {
        filter: filter,
        clieId: clieId,
        forms: options["forms_recomendations"],
        segtyId: segtyId,
        contId: contractId,
      },
      this.httpOptions
    );
  }

  //obtiene estructura de inputs y valores (si es que se le proporciona id) de una recomendacion.
  getRecommendation(formId, recommendationId): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "recommendation/get",
      {
        formId: formId,
        recommendationId: recommendationId,
      },
      this.httpOptions
    );
  }

  //obtiene estructura de inputs y valores (si es que se le proporciona id) de una recomendacion.
  saveRecommendation(form:FormData): Observable<any> {
    return this.http.post(
      this.baseUrl + "recommendation/save",
      form,
      {
        headers: new HttpHeaders({
          Authorization: "Bearer " + sessionStorage.getItem("token")
        }),
      }
    );
  }
}
