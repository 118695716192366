import { Component, OnInit, Renderer2, Input, Output, TemplateRef, EventEmitter, ViewChild, ElementRef,ComponentFactoryResolver, ViewContainerRef, AfterViewInit, ChangeDetectorRef, HostListener   } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SnotifyService } from 'ng-snotify';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { FormBuilder, FormGroup,FormControl,Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material';
import { PrintService } from './../../../../services/print.service'
import { DataTableComponent } from './../../../dashboard/data-table/data-table.component';
import { HorizontalChartComponent } from '../../../dashboard/charts/horizontal-chart/horizontal-chart.component';
import { LineChartComponent } from '../../../dashboard/charts/line-chart/line-chart.component';
import { ViewImageModalComponent } from './../../view-inage-modal/view-image-modal.component';
import { VerticalChartComponent } from '../../../dashboard/charts/vertical-chart/vertical-chart.component';

import * as moment from 'moment';
import { TableAndChartHandlerComponent } from 'src/app/components/dashboard/table-and-chart-handler/table-and-chart-handler.component';

import { GroupedVerticalChartComponent } from 'src/app/components/dashboard/charts/grouped-vertical-chart/grouped-vertical-chart.component';
import { StackgroupHorizontalChartComponent } from 'src/app/components/dashboard/charts/stackgroup-horizontal-chart/stackgroup-horizontal-chart.component';

import { DashboardFilterKdmComponent } from 'src/app/components/dashboard_html/kdm/gestion-residuos/dashboard-filter/dashboard-filter.component';
import { StackedVerticalChartComponent } from 'src/app/components/dashboard/charts/stacked-vertical-chart/stacked-vertical-chart.component';
import { PieChartComponent } from '../../../dashboard/charts/pie-chart/pie-chart-component';
import { AdvancedPieChartComponent } from '../../../dashboard/charts/advanced-pie-chart/advanced-pie-chart.component';
import { saveAs } from 'file-saver/';
import { ExcelService } from './../../../../services/excel.service'

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';

export interface Mes {
  value: number;
  nombre: string;
}

export interface Anio {
  value: number;
  nombre: number;
}

export interface contratos {
  value: number;
  cont_name: string;
  cont_code: string;
}

export interface residuos {
  value: number;

}

export interface graphic {
  value: number;
  daphagra_title: string;
}

@Component({
  selector: 'app-gestion-residuos',
  templateUrl: './gestion-residuos.component.html',
  styleUrls: ['./gestion-residuos.component.css']
})
export class GestionResiduosComponent extends TableAndChartHandlerComponent implements OnInit,AfterViewInit {

  public user_id = JSON.parse(sessionStorage.getItem('userData')).user_id;
  public clie_id = JSON.parse(sessionStorage.getItem('userData')).clie_id;

  public loadingServices = 0;
  public listServicios = '';
  
  noSpeciesGroups = false;
  maxPlaguePresence = 0;
  filters = {};
  

  month: Mes[] = [
    {value: 1, nombre: 'Enero'},
    {value: 2, nombre: 'Febrero'},
    {value: 3, nombre: 'Marzo'},
    {value: 4, nombre: 'Abril'},
    {value: 5, nombre: 'Mayo'},
    {value: 6, nombre: 'Junio'},
    {value: 7, nombre: 'Julio'},
    {value: 8, nombre: 'Agosto'},
    {value: 9, nombre: 'Septiembre'},
    {value: 10, nombre: 'Octubre'},
    {value: 11, nombre: 'Noviembre'},
    {value: 12, nombre: 'Diciembre'},
  ];

  year: Anio[] = [
    {value: 2017, nombre: 2017},
    {value: 2018, nombre: 2018},
    {value: 2019, nombre: 2019},
    {value: 2020, nombre: 2020},
    {value: 2021, nombre: 2021},
    {value: 2022, nombre: 2022},
    {value: 2023, nombre: 2023},
    {value: 2024, nombre: 2024},
    {value: 2025, nombre: 2025},
  ];

  dateNow = '';
  dateLast = '';
  globalFromDateValue: string = '';
  globalToDateValue: string = '';

  public taskPermission = {
    allowed: false,
    initialDate: 1,
    finalDate: 30,
    showTasksMonthsAgo:12
  };

  
  public dataContract:contratos[]=[];
  public contratosTodos=[];
  public dataGraphic:graphic[]=[];
  public dataResiduos:residuos[]=[];
  public dataPlanta:residuos[]=[];
  public dataTipoDisposicion:residuos[]=[];
  public dataContracts:residuos[]=[];


 
  barMaxWidth = 15;

  formatNumberSeparator(val) {
    if (val >= 0) {
      return new Intl.NumberFormat("es-CL").format(val);
    }
    return val;
  } 
  //Modal Añadir comentario
  public modalCommentDash: BsModalRef;
  public description;
  public dashboard_name;
  //Ver comentarios
  public modalViewCommentDash: BsModalRef;  
  public pagina: number = 1;
  public porPagina: number = 4;   // cantidad de filas a mostrar en la paginacion
  public porPaginaListadoTarea: number = 20;
  public paginaListadoTarea: number = 1;
  public editComment = false;
  //Edicion de comentario
  public descriptionEdicion;
  public anio;
  public mes;
  public fecha;
  public id_table_to_edit;
  public dadabikd_id; //
  //edicion comentario actividad roedores

  // edicion de comentario captura segun mes
  public coancadime_commentary;
  // edicion de comentario consumo segun mes
  public coancodime_commentary;
  //TUV mensual
  public daprsametu_commentary;
  //TUV semanal
  public daprsasetu_commentary;
  // listado de comentario actividad roedores
  public listCommentActiRoeMesZona;
  public listCommentCompAnualCapturaMes;
  public listCommentCompAnualConsumoMes;
  public loadingCommentActiRoeMesZona = 0;
  public loadingCommentCompAnualCapturaMes = 0;
  public loadingCommentCompAnualConsumoMes = 0;
  public loadingTuvModal = false;
  // listado de comentario tUV



  public listAllServices;
  public loadingAllServices = 0;
  
  displayedColumns: string[] = ['date','patente', 'trabajador', 'tipoVisita', 'nGuia','fechaRelleno','nIngresoRelleno','tonIngeso','dispFinal','patenteDisp','tipoResiduo' ,'acciones'];
  pageSizeOptions: number[] = [15, 25, 50, 75];
  @ViewChild('allServicesSort', {read: MatSort}) allServicesSort: MatSort;
  @ViewChild('paginatorAllResiduos', {read: MatPaginator}) paginatorAllResiduos: MatPaginator;
  public servicesPage = 1;


  //visualizacion graficos / tablas
  public viewGraphic = true;
  public loadingEdit = 0;
  //public cant_pending_task = 0;
  //public cant_pending_task_all =0;
  //public task_list_size = 0;
  public showsServicesTable =0;
  public clients = [];
  //loagin grafico modal template
  public loadingGraphicModal = 0;
  public loadingTuv;
  public loadingDeviceActivity = { perimetroExterior: false, perimetroEstructural: false, interior: false };
 
  //configuracion del modal
  public config = {
    backdrop: true , //Fondo oscuro 
    ignoreBackdropClick: true, //para que no se cierre al clickear fuera del formulario 
    class: 'modal-lg',
    keyboard: false //para que no se cierre al apretar escape 
  };
  public selectDates = {
    detection: ''
    , verification: ''
  };
  detectionDateChange(event) {
    this.selectDates['detection'] = (event.value.toLocaleDateString().split('-')).reverse().join('-');
    //this.filters();
  }
  //count listado done comentario
  public countActiRoeMesZona = 0;
  public countCompAnualCapturaMes = 0;
  public countCompAnualConsumoMes = 0;
  public countTUVSemanaPlaga = 0;
  public countTUVMensual = 0;
  public countTUVSemanal = 0;
  

  public contractsListText : string = 'Ninguno'


  public selectedContract;
  public servicesOptions;
  public residuosServicesList = [];
  //
  public deviceCharts = [];
  public maxDeviceValue = 0;
  
  public titles = {
    residuosPlanta:'Cargando...',
    residuosTipo:'Cargando...',
    residuosTipoTratamiento:'Cargando...',
    residuosGestionadoAnio:'Cargando...'
  }

  //Referencias para graficos y tablas
  public currentChart: any = null;
  public currentTable: any = null;
  public currentTipoChart: any = null;
  public currentTipoTable: any = null;
  public currentTipoTratamientoChart: any = null;
  public currentTipoTratamientoTable: any = null;
  public currentGestionadoAnioChart: any = null;
  public currentGestionadoAnioTable: any = null;

  @ViewChild('testRef') testRef: ElementRef;
  
  
  @ViewChild('residuosPlantaChartContainer', {read: ViewContainerRef}) residuosPlantaChartContainer: ViewContainerRef;
  @ViewChild('residuosPlantaTablaContainer', {read: ViewContainerRef}) residuosPlantaTablaContainer: ViewContainerRef;
  @ViewChild('residuosTipoChartContainer', {read: ViewContainerRef}) residuosTipoChartContainer: ViewContainerRef;
  @ViewChild('residuosTipoTablaContainer', {read: ViewContainerRef}) residuosTipoTablaContainer: ViewContainerRef;
  @ViewChild('residuosTipoTratamientoChartContainer', {read: ViewContainerRef}) residuosTipoTratamientoChartContainer: ViewContainerRef;
  @ViewChild('residuosTipoTratamientoTablaContainer', {read: ViewContainerRef}) residuosTipoTratamientoTablaContainer: ViewContainerRef;
  @ViewChild('residuosGestionadoAnioChartContainer', {read: ViewContainerRef}) residuosGestionadoAnioChartContainer: ViewContainerRef;
  @ViewChild('residuosGestionadoAnioTablaContainer', {read: ViewContainerRef}) residuosGestionadoAnioTablaContainer: ViewContainerRef;
  //@ViewChild('residuosServicesChartContainer', {read: ViewContainerRef}) residuosServicesChartContainer: ViewContainerRef;
  
  
  
  //filters
  @ViewChild('contractFilters') contractsFilters: DashboardFilterKdmComponent;
  @ViewChild('residuosPlantaFilter') residuosPlantaFilter: DashboardFilterKdmComponent;
  @ViewChild('residuosTipoFilter') residuosTipoFilter: DashboardFilterKdmComponent;
  @ViewChild('residuosTipoTratamientoFilter') residuosTipoTratamientoFilter: DashboardFilterKdmComponent;
  @ViewChild('residuosGestionadosAnioFilter') residuosGestionadosAnioFilter: DashboardFilterKdmComponent;
  @ViewChild('residuosServicesFilter') residuosServicesFilter: DashboardFilterKdmComponent;

  

  chartFilters = {
    'residuosPlanta' : {'contracts':'','desde':this.dateNow,'hasta':this.dateLast,'tipoResiduo':[],'planta':[],'tipoDisposicion':[]}
    ,'residuosTipo' :  {'contracts':'','desde':'','hasta':'','tipoResiduo':[]}
    ,'residuosTipoTratamiento' :  {'contracts':'','desde':'','hasta':'','tipoDisposicion':[]}
    ,'residuosGestionadosAnio' :  {'years':[],'months':[],'contracts':'','desde':'','hasta':'','tipoResiduo':[]}
    ,'residuosServices' :  {'contracts':'','desde':'','hasta':''}
  };

  constructor(
    private valPermiso: ValidaPermisosService,
    private dashboard: DashboardService,
    private http:HttpClient,
    private route:Router,
    private notify: SnotifyService,
    private modalService: BsModalService,
    public printService : PrintService,
    public cfResolver : ComponentFactoryResolver,
    public cdRef : ChangeDetectorRef ,
    public formBuilder : FormBuilder,
    public servicesContainer: ViewContainerRef,
    public excelService : ExcelService,
    ) { 
      super(cfResolver);

      let date = new Date()
      let year = date.getFullYear()
      let day = `${date.getDate()}`;
      let month = `${date.getMonth() + 1}`;
      if(date.getDate()<10) day = `0${day}`;
      if((date.getMonth() + 1)<10) month = `0${month}`;
      this.dateNow = `${year}-${month}-${day}`;
      this.dateLast = this.addDaysDate(this.dateNow,-30);

      this.chartFilters['residuosPlanta']['desde'] = this.dateLast;
      this.chartFilters['residuosPlanta']['hasta'] = this.dateNow;

      this.chartFilters['residuosTipo']['desde'] = this.dateLast;
      this.chartFilters['residuosTipo']['hasta'] = this.dateNow;
      
      this.chartFilters['residuosTipoTratamiento']['desde'] = this.dateLast;
      this.chartFilters['residuosTipoTratamiento']['hasta'] = this.dateNow;
      
      this.chartFilters['residuosGestionadosAnio']['desde'] = this.dateLast;
      this.chartFilters['residuosGestionadosAnio']['hasta'] = this.dateNow;

      this.chartFilters['residuosServices']['desde'] = this.dateLast;
      this.chartFilters['residuosServices']['hasta'] = this.dateNow;

      this.titles = {
        residuosPlanta: 'Residuos gestionados por planta',
        residuosTipo: 'Distribución por tipo de residuo',
        residuosTipoTratamiento: 'Tipo de Tratamiento',
        residuosGestionadoAnio: 'Residuos Gestionados por Año'
      };

    }// FIN DEL CONSTRUCTOR

  nextServicesPage(){
    this.loadResiduoservicios(null,this.servicesPage+1);
  }

  prevServicesPage(){
    this.loadResiduoservicios(null,this.servicesPage-1);
  }



  /*
  openTask(){
    this.viewGraphic = false;
    $('.charts-container').hide(1000,function(){
      $('.tasks-container').show(1000);
    });
    $('#btnGraphic').removeClass('active');
    
    if( typeof this.listAllServices === "undefined" ){
      this.loadResiduoservicios(null,1); 
    }
  }
  */
 /*
  openGraphic(){
    this.viewGraphic = true;
    $('.tasks-container').hide(1000,function(){
      $('.charts-container').show(1000);
    });
    $('#btnTask').removeClass('active');
    // $('#btnGraphic').addClass('active');
  }*/

 

  graphicActiRoedores(){
    $('.app_menu').css({"max-height":"3000px"});
  }

  graphicTUV(){
    $('.app_menu').css({"max-height":"2700px"});
  }

  showImageService(id,anio,mes,table,related_id,cont_id,tag){

    this.anio = anio;
    this.mes = mes;
    this.dadabikd_id = id;
    this.id_table_to_edit = related_id;
    

    this.modalViewCommentDash = this.modalService.show(ViewImageModalComponent, this.config);

  
    var filter = {
     
    }

    this.dashboard.getresiduosImagen(id).subscribe(
      dato=>{   
        this.modalViewCommentDash.content.setData(dato)
         
       
        this.modalViewCommentDash.content.setData(dato['images']);
        this.modalViewCommentDash.content.setLoading(false);
        this.modalViewCommentDash.content.setType("complete");
        
        
        this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
          this.servicesContainer = ref;
          if (this.servicesContainer) {
            
          }
        })
                      
    },error=>{
      console.log(error);
      this.loadingAllServices= 3;
      this.notify.warning("Error al cargar imagenes:\nConexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })   
      
  }


  showLoading(){
    this.loadingDeviceActivity = { perimetroExterior: true, perimetroEstructural: true, interior: true };
  }


  aplyFiltersToAllCharts(emptyCharts: boolean = false) {
    // Si emptyCharts es true, inicializa los gráficos y tablas sin datos
    if (emptyCharts) {
      // Residuos Planta
      if (this.residuosPlantaChartContainer && this.residuosPlantaTablaContainer) {
        this.residuosPlantaChartContainer.clear();
        this.residuosPlantaTablaContainer.clear();
        this.currentChart = this.createHorizontalChart(
          this.residuosPlantaChartContainer, 
          'disposicion', 
          'Residuos gestionados', 
          ['col-lg-12']
        );
        this.currentTable = this.createTable(
          this.residuosPlantaTablaContainer,
          '',
          [],
          ['col-lg-12']
        );
        this.currentChart.setDataSource([]);
        this.currentTable.setDataSource([]);
      }

      // Residuos Tipo
      if (this.residuosTipoChartContainer && this.residuosTipoTablaContainer) {
        this.residuosTipoChartContainer.clear();
        this.residuosTipoTablaContainer.clear();
        this.currentTipoChart = this.createGroupedVerticalChart(
          this.residuosTipoChartContainer,
          'residuos',
          'Tipo de Residuos gestionados',
          ['col-lg-12']
        );
        this.currentTipoTable = this.createTable(
          this.residuosTipoTablaContainer,
          '',
          [],
          ['col-lg-12']
        );
        this.currentTipoChart.setDataSource([]);
        this.currentTipoTable.setDataSource([]);
      }

      // Residuos Tipo Tratamiento
      if (this.residuosTipoTratamientoChartContainer && this.residuosTipoTratamientoTablaContainer) {
        this.residuosTipoTratamientoChartContainer.clear();
        this.residuosTipoTratamientoTablaContainer.clear();
        this.currentTipoTratamientoChart = this.createStackedVerticalChart(
          this.residuosTipoTratamientoChartContainer,
          'tipoResiduo',
          'Residuos gestionados',
          ['col-lg-12']
        );
        this.currentTipoTratamientoTable = this.createTable(
          this.residuosTipoTratamientoTablaContainer,
          '',
          [],
          ['col-lg-12']
        );
        this.currentTipoTratamientoChart.setDataSource([]);
        this.currentTipoTratamientoTable.setDataSource([]);
      }

      // Residuos Gestionado Anio
      if (this.residuosGestionadoAnioChartContainer && this.residuosGestionadoAnioTablaContainer) {
        this.residuosGestionadoAnioChartContainer.clear();
        this.residuosGestionadoAnioTablaContainer.clear();
        this.currentGestionadoAnioChart = this.createGroupedVerticalChart(
          this.residuosGestionadoAnioChartContainer,
          'periodo',
          'Residuos gestionados por año',
          ['col-lg-12']
        );
        this.currentGestionadoAnioTable = this.createTable(
          this.residuosGestionadoAnioTablaContainer,
          '',
          [],
          ['col-lg-12']
        );
        this.currentGestionadoAnioChart.setDataSource([]);
        this.currentGestionadoAnioTable.setDataSource([]);
      }

      return; // No cargar datos
    }

    // Carga normal de gráficos y tablas con datos
    this.loadResiduosPlantaChart(this.residuosPlantaChartContainer, this.residuosPlantaTablaContainer);
    this.loadResiduosTipoChart(this.residuosTipoChartContainer, this.residuosTipoTablaContainer);
    this.loadResiduosTipoTratamientoChart(this.residuosTipoTratamientoChartContainer, this.residuosTipoTratamientoTablaContainer);
    this.loadResiduosGestionadoAnioChart(this.residuosGestionadoAnioChartContainer, this.residuosGestionadoAnioTablaContainer);
    this.loadResiduoservicios(null, 1);
  }//filtros

  searchTableResiduos(filterValue) {
    this.listAllServices.filter = filterValue.trim().toLowerCase();
  }

  validaPermisos(permiso){
      return this.valPermiso.validaPermisos(permiso);
  }

  getNumberMonth(mes){
    if(mes == 'Enero'){ return '01'}
    if(mes == 'Febrero'){ return '02'}
    if(mes == 'Marzo'){ return '03'}
    if(mes == 'Abril'){ return '04'}
    if(mes == 'Mayo'){ return '05'}
    if(mes == 'Junio'){ return '06'}
    if(mes == 'Julio'){ return '07'}
    if(mes == 'Agosto'){ return '08'}
    if(mes == 'Septiembre'){ return '09'}
    if(mes == 'Octubre'){ return '10'}
    if(mes == 'Noviembre'){ return '11'}
    if(mes == 'Diciembre'){ return '12'}
  }

  ngAfterViewInit(){
    $('.app_menu').css({"max-height":"2700px"});

    this.dashboard.getTasksPermission().subscribe(
      data => {
        this.taskPermission = data['taskPermission'];
        sessionStorage.setItem("showTasksMonthsAgo",data['taskPermission']["showTasksMonthsAgo"]);
      } 
    )
    
    this.loadResiduosFiltrosKdm();

    this.dashboard.get_contracts_profile(this.user_id,'gestion-residuos').subscribe(
      data=>{

          this.dataContract  = data;    
          this.contratosTodos = data;
          this.selectedContract = data[0].value;

          this.setFilterContracts(this.residuosPlantaFilter,this.dataContract);
          this.setFilterContracts(this.residuosTipoFilter,this.dataContract);
          this.setFilterContracts(this.residuosTipoTratamientoFilter,this.dataContract);
          this.setFilterContracts(this.residuosGestionadosAnioFilter,this.dataContract);
          this.setFilterContracts(this.residuosServicesFilter,this.dataContract);
                
          var currentYear = new Date().getFullYear();
          //this.residuosPlantaFilter.setDefaultYears(currentYear);
          var currentMonth = (new Date().getMonth()) +1;
          //this.residuosPlantaFilter.setDefaultMonths([currentMonth]);

          //this.residuosServicesFilter.setDefaultContract( data[0].value );
          //this.updateContracts(data[0].value, 'SET_DEFAULT');

          this.aplyFiltersToAllCharts(true);

          this.servicesOptions = {
            years : []
            , months : []
            , contracts: [data[0].value]
          };
          this.loadResiduoservicios(null,1);
          
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  


    this.dashboard.listGraphicTittle().subscribe(
        data=>{
          this.dataGraphic  = data;
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  

  }



  parseContracts(contracts){
    var contractsList = [];
    for( let contract of contracts){
      contractsList.push({ id: contract.value, value: contract.cont_name, code: contract.cont_code });
    }
    return contractsList;
  }

  setFilterContracts( filter :DashboardFilterKdmComponent ,contracts){
    filter.setData('contract', this.parseContracts(contracts) );
  }
  setShowContracts( filter :DashboardFilterKdmComponent ,value){
    filter.setShowContracts(value);
  }
  setShowDisposition( filter :DashboardFilterKdmComponent ,value){
    filter.setShowDisposition(value);
  }
  setShowTipoResiduo( filter :DashboardFilterKdmComponent ,value){
    filter.setShowTipoResiduo(value);
  }
  setShowTipoDisposicion( filter :DashboardFilterKdmComponent ,value){
    filter.setShowTipoDisposicion(value);
  }
  setFilterResiduos( filter :DashboardFilterKdmComponent ,residuos){
    //filter.setData('residuos', residuos );
    filter.setData('tipoResiduo', residuos );
  }
  setFilterPlanta( filter :DashboardFilterKdmComponent ,planta){
    filter.setData('planta', planta );
  }
  setFilterDesde( filter :DashboardFilterKdmComponent ,desde){
    filter.setData('desde', desde );
  }
  setFilterHasta( filter :DashboardFilterKdmComponent ,hasta){
    filter.setData('hasta', hasta );
  }
  setFilterTipoDisposicion( filter :DashboardFilterKdmComponent ,tipoDisposicion){
    filter.setData('tipoDisposicion', tipoDisposicion );
  }

  ngOnInit() {    
    this.filters = { desde:'',hasta:'',tipoResiduo:[] , planta: [], tipoDisposicion: [],"month":[],"year":[] };
  }

  /*
  onPrintGraphic(graphicName){
    var f = null;
    if(graphicName=='ResiduosPlanta'){
      f = this.chartFilters['residuosPlanta'];  
    }else if(graphicName=="ResiduosTipo"){
      f = this.chartFilters['residuosTipo'];  
    }else if(graphicName=="ResiduosTipoTratamiento"){
      f = this.chartFilters['residuosTipoTratamiento'];  
    }else if(graphicName=="ResiduosGestionadosAnio"){
      f = this.chartFilters['residuosGestionadosAnio'];  
    }else if(graphicName=="residuosServices"){
      f = this.chartFilters['residuosServices'];
    }
   
    console.log(f)
    $(".btn-print").prop('disabled',true);
    this.notify.info('Espere mientras se genera su documento...',{timeout:2500})
    this.printService.printDocument('graphic', {
        graphic : graphicName,
        desde : f.desde,
        hasta : f.hasta,
        tipoResiduo : f.tipoResiduo,
        planta : f.planta,
        tipoDisposicion:f.tipoDisposicion,
        contIds:f.contracts
    });
  }*/
  

  //FUNCION IMPRESION GRAFICOS INDIVIDUAL
  async printPdfInd(filterSelector: string, graphicSelector: string) {
    const filterElement = document.querySelector(filterSelector) as HTMLElement;
    const graphicElement = document.querySelector(graphicSelector) as HTMLElement;
  
    if (!filterElement || !graphicElement) {
      console.error('No se encontraron los elementos para capturar.');
      return;
    }
  
    // Clonar el contenedor de filtros
    const filterClone = filterElement.cloneNode(true) as HTMLElement;
    filterClone.classList.add('pdf-styles');
  
    // Eliminar los botones de limpiar y el icono del datepicker
    filterClone.querySelectorAll('mat-icon, .mat-datepicker-toggle-default-icon').forEach(el => el.remove());
    filterClone.querySelectorAll('button.mat-icon-button').forEach(button => {
      if (button.querySelector('svg')) {
        button.remove();
      }
    });

    filterClone.querySelectorAll('.filterButtons').forEach(btn => btn.remove());

    const wrapperDiv = document.createElement('div');
    Object.assign(wrapperDiv.style, {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    });
  
    const titleElement = document.createElement('div');
    titleElement.textContent = 'Filtros:';
    Object.assign(titleElement.style, {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '10px',
      width: '80%',
      textAlign: 'left',
    });
  
    filterClone.prepend(titleElement);
  
    //Modificar la parte donde se manejan los campos de formulario
    filterClone.querySelectorAll('mat-form-field').forEach((field) => {
      let label = field.querySelector('label').textContent.trim() || '';
      if(!label.endsWith(':')){
        label += ':';
      }
      const isDateField = field.querySelector('input[matInput]') !== null;

      const fieldContainer = document.createElement('div');
      fieldContainer.style.marginBottom = '15px';

      const labelElement = document.createElement('div');
      labelElement.textContent = label;
      labelElement.classList.add('pdf-filter-label');
      Object.assign(labelElement.style, {
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '5px',
      });

      const valueContainer = document.createElement('div');
      Object.assign(valueContainer.style, {
        border: 'none',
        borderTop: '1px solid #ddd',
        padding: '8px',
        borderRadius: '4px',
        backgroundColor: 'white',
      });

      if (isDateField) {
        const originalInput = field.querySelector('input[matInput]') as HTMLInputElement;
        valueContainer.textContent = originalInput.value || '';
      } else {
        const selectedText = field.querySelector('.mat-select-value').textContent.trim() || '';

        if (selectedText.includes(',')) {
          const selections = selectedText.split(',').map(s => s.trim());
          if (selections.length > 2) {
            valueContainer.textContent = `${selections[0]}, ${selections[1]}, ...`;
          } else {
            valueContainer.textContent = selectedText;
          }
        } else {
          valueContainer.textContent = selectedText;
        }
      }

      field.innerHTML = '';
      fieldContainer.appendChild(labelElement);
      fieldContainer.appendChild(valueContainer);
      field.appendChild(fieldContainer);
    });

    filterClone.querySelectorAll('input[matInput]').forEach((input, index) => {
      const originalInput = filterElement.querySelectorAll('input[matInput]')[index] as HTMLInputElement;
      if (input instanceof HTMLInputElement && originalInput) {
        input.value = originalInput.value;
      }
    });

    // Mostrar el texto seleccionado en mat-selects correctamente alineado
    filterClone.querySelectorAll('mat-form-field').forEach(selectField => {
      const matSelect = selectField.querySelector('mat-select');
      if (matSelect) {
        const selectedText = selectField.querySelector('.mat-select-value').textContent.trim() || '';
        if (selectedText) {
          let textElement = selectField.querySelector('.selected-value') as HTMLElement;
          if (!textElement) {
            textElement = document.createElement('div');
            textElement.classList.add('selected-value');
            selectField.appendChild(textElement);
          }
          textElement.textContent = selectedText;
          Object.assign(textElement.style, {
            position: 'absolute',
            top: '30px',
            left: '12px',
            fontSize: '16px',
            color: '#000',
            fontWeight: '500',
            pointerEvents: 'none',
            backgroundColor: 'white',
            padding: '2px 5px',
            borderRadius: '4px',
            width: 'calc(100% - 24px)',
            textAlign: 'left',
          });
        }
      }
    });
  
    // Crear un contenedor oculto para la captura
    const hiddenContainer = document.createElement('div');
    Object.assign(hiddenContainer.style, {
      position: 'absolute',
      top: '-10000px',
      left: '-10000px',
      width: filterElement.offsetWidth + 'px',
    });
    document.body.appendChild(hiddenContainer);
    hiddenContainer.appendChild(filterClone);

    Swal.fire({
      title: 'Generando documento, espere un momento...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000,
      onOpen: () => Swal.showLoading(),
    }).then(() => {
      Swal.fire({
        title: 'Documento generado',
        icon: 'success',
        timer: 2500,
        showConfirmButton: false,
      });
    });
  
    setTimeout(async () => {
      try {
        const images = await this.captureImgInd([filterClone, graphicElement]);
        hiddenContainer.remove();
        this.generatePdf(images);
      } catch (error) {
        console.error('Error al capturar los gráficos:', error);
        hiddenContainer.remove();
      } finally {
        Swal.close();
      }
    }, 100);
  }

  async captureImgInd(elements: HTMLElement[]): Promise<{ imgData: string, imgHeight: number }[]>{
    const imgWidth = 230;
    const captureElement = async (element: HTMLElement): Promise<{ imgData: string, imgHeight: number }> => {
      try {
        const canvas = await html2canvas(element, { scale: 2, });
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        return { imgData: canvas.toDataURL('image/jpeg'), imgHeight };
      } catch (error) {
        console.error("Error al capturar el elemento:", element, error);
        return { imgData: '', imgHeight: 0 };
      }
    };

    const imageResults: { imgData: string, imgHeight: number }[] = [];
    for (const element of elements) {
      try {
        const result = await captureElement(element);
        imageResults.push(result);
      } catch (error) {
        console.error("Error al procesar el elemento:", element, error);
      }
    }
    return imageResults;
  }

  //FUNCION IMPRESION GRAFICOS GLOBAL
  async printPdfAll() {
    const filterElements = document.querySelectorAll('.capture-filters') as NodeListOf<HTMLElement>;
    const graphicElements = document.querySelectorAll('.capture-graphic') as NodeListOf<HTMLElement>;
  
    if (!filterElements.length || !graphicElements.length) {
      console.error('No se encontraron los elementos para capturar.');
      return;
    }
  
    const clonedElements: HTMLElement[] = [];
  
    Swal.fire({
      title: 'Generando documento, espere un momento...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000,
      onOpen: () => Swal.showLoading(),
    });
    
    filterElements.forEach((filterElement, index) => {
      // Clonar el contenedor de filtros
      const filterClone = filterElement.cloneNode(true) as HTMLElement;
      filterClone.classList.add('pdf-styles');
  
      // Eliminar elementos innecesarios
      filterClone.querySelectorAll('mat-icon, .mat-datepicker-toggle-default-icon').forEach(el => el.remove());
      filterClone.querySelectorAll('button.mat-icon-button').forEach(button => {
        if (button.querySelector('svg')) {
          button.remove();
        }
      });
      filterClone.querySelectorAll('.filterButtons').forEach(btn => btn.remove());

      const titleElement = document.createElement('div');
      titleElement.textContent = 'Filtros:';
      Object.assign(titleElement.style, {
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '10px',
        width: '80%',
        textAlign: 'left',
      });
      filterClone.prepend(titleElement);

      // Procesar campos de formulario
      filterClone.querySelectorAll('mat-form-field').forEach((field) => {
        let label = field.querySelector('label').textContent.trim() || '';
        if (!label.endsWith(':')) {
          label += ':';
        }
        const isDateField = field.querySelector('input[matInput]') !== null;

        const fieldContainer = document.createElement('div');
        fieldContainer.style.marginBottom = '15px';

        const labelElement = document.createElement('div');
        labelElement.textContent = label;
        labelElement.classList.add('pdf-filter-label');
        Object.assign(labelElement.style, {
          fontSize: '14px',
          fontWeight: 'bold',
          marginBottom: '5px',
        });

        const valueContainer = document.createElement('div');
        Object.assign(valueContainer.style, {
          border: 'none',
          borderTop: '1px solid #ddd',
          padding: '8px',
          borderRadius: '4px',
          backgroundColor: 'white',
        });

        if (isDateField) {
          const originalInput = field.querySelector('input[matInput]') as HTMLInputElement;
          valueContainer.textContent = originalInput.value || '';
        } else {
          const selectedText = field.querySelector('.mat-select-value').textContent.trim() || '';
          if (selectedText.includes(',')) {
            const selections = selectedText.split(',').map(s => s.trim());
            if (selections.length > 2) {
              valueContainer.textContent = `${selections[0]}, ${selections[1]}, ...`;
            } else {
              valueContainer.textContent = selectedText;
            }
          } else {
            valueContainer.textContent = selectedText;
          }
        }

        field.innerHTML = '';
        fieldContainer.appendChild(labelElement);
        fieldContainer.appendChild(valueContainer);
        field.appendChild(fieldContainer);
      });
  
      // Crear wrapper para el conjunto de filtros y gráfico
      const wrapperDiv = document.createElement('div');
      Object.assign(wrapperDiv.style, {
        width: filterElement.offsetWidth + 'px',
        marginBottom: '20px',
        padding: '10px',
        backgroundColor: 'white',
        pageBreakAfter: 'always',
      });
      
      wrapperDiv.appendChild(filterClone);
          
      // Clonar el gráfico y mantener sus dimensiones originales
      if (graphicElements[index]) {
        const graphicContainer = document.createElement('div');
        const originalWidth = graphicElements[index].offsetWidth;
        const originalHeight = graphicElements[index].offsetHeight;
            
        Object.assign(graphicContainer.style, {
          width: originalWidth + 'px',
          height: originalHeight + 'px',
          position: 'relative',
          margin: '10px 0'
        });
    
        const graphicClone = graphicElements[index].cloneNode(true) as HTMLElement;
            
        graphicClone.querySelectorAll('.ngx-charts-legend-series, .legend-container, .legend-items').forEach(item => {
          Object.assign((item as HTMLElement).style, {
            visibility: 'visible',
            opacity: '1',
            display: 'block'
          });
        });

        Object.assign(graphicClone.style, {
          width: '100%',
          height: '100%'
        });
    
        graphicContainer.appendChild(graphicClone);
        wrapperDiv.appendChild(graphicContainer);
      }
      
      clonedElements.push(wrapperDiv);
    });
  
    // Crear contenedor oculto para la captura
    const hiddenContainer = document.createElement('div');
    Object.assign(hiddenContainer.style, {
      position: 'absolute',
      top: '-10000px',
      left: '-10000px',
      width: filterElements[0].offsetWidth + 'px'
    });
    document.body.appendChild(hiddenContainer);
  
    // Agregar elementos clonados al contenedor oculto
    clonedElements.forEach(el => hiddenContainer.appendChild(el));
  
    setTimeout(async () => {
      try {
        const images = await this.captureImgAll(clonedElements);
        this.generatePdf(images);
      } catch (error) {
        console.error('Error al capturar los gráficos:', error);
      } finally {
        hiddenContainer.remove();
        Swal.close();
      }
    }, 500);
  }

  async captureImgAll(elements: HTMLElement[]) {
    const imgWidth = 230;
    const captureElement = async (element: HTMLElement) => {
      return html2canvas(element, {
        scale: 2,
        onclone: (element) => {
          element.querySelectorAll('.ngx-charts-legend-series, .legend-container, .legend-items').forEach(item => {
            (item as HTMLElement).style.visibility = 'visible';
            (item as HTMLElement).style.opacity = '1';
            (item as HTMLElement).style.display = 'block';
          });
        }
      }).then(canvas => {
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        return { imgData: canvas.toDataURL('image/jpeg', 1.0), imgHeight };
      });
    };
      
    return Promise.all(Array.from(elements).map(el => captureElement(el)));
  }

  //FUNCION GENERACION PDF
  generatePdf(images: { imgData: string, imgHeight: number }[]) {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 10;
    const imgWidth = 190;  
    
    const centerX = (pageWidth - imgWidth) / 2;

    let currentY = margin;
    const title = "Reporte de Gráficos";
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(20);
    pdf.text(title, pageWidth / 2, currentY, { align: "center" });
    currentY += 15;
  
    // Por cada par de imágenes (filtros + gráfico)
    for (let i = 0; i < images.length; i += 2) {
        if (i > 0) {
            pdf.addPage();
            currentY = margin;
        }
  
        if (images[i]) {
            const filterHeight = images[i].imgHeight;
            if (currentY + filterHeight > pageHeight) {
                pdf.addPage();
                currentY = margin;
            }
            pdf.addImage(images[i].imgData, 'JPEG', centerX, currentY, imgWidth, filterHeight);
            currentY += filterHeight + 10;
        }
  
        if (images[i + 1]) {
            const graphicHeight = images[i + 1].imgHeight;
            if (currentY + graphicHeight > pageHeight) {
                pdf.addPage();
                currentY = margin;
            }
            pdf.addImage(
                images[i + 1].imgData, 
                'JPEG', 
                centerX, 
                currentY, 
                imgWidth, 
                graphicHeight
            );
        }
    }
  
    pdf.output('dataurlnewwindow', { filename: 'Graficos.pdf' });
  }

loadResiduosFiltrosKdm(){
  this.dashboard.getresiduosFiltrosKdm().subscribe(
    data=>{

      this.dataResiduos  = data['tipo_residuo'];
      this.dataPlanta  = data['planta'];
      this.dataTipoDisposicion  = data['tipo_disposicion'];
      this.dataContracts = data['contracts'];
      
      this.setShowContracts(this.residuosPlantaFilter,true)
      this.setFilterResiduos(this.residuosPlantaFilter,this.dataResiduos);
      this.setFilterPlanta(this.residuosPlantaFilter,this.dataPlanta);
      this.setFilterDesde(this.residuosPlantaFilter,this.dateLast);
      this.setFilterHasta(this.residuosPlantaFilter,this.dateNow);
      this.setFilterTipoDisposicion(this.residuosPlantaFilter,this.dataTipoDisposicion);
      this.setShowDisposition(this.residuosPlantaFilter,true);
      this.setShowTipoResiduo(this.residuosPlantaFilter,true);
      this.setShowTipoDisposicion(this.residuosPlantaFilter,false);

      this.setShowContracts(this.residuosTipoFilter,true)
      this.setFilterResiduos(this.residuosTipoFilter,this.dataResiduos);
      this.setFilterPlanta(this.residuosTipoFilter,this.dataPlanta);
      this.setFilterDesde(this.residuosTipoFilter,this.dateLast);
      this.setFilterHasta(this.residuosTipoFilter,this.dateNow);
      this.setFilterTipoDisposicion(this.residuosTipoFilter,this.dataTipoDisposicion);
      this.setShowDisposition(this.residuosTipoFilter,false);
      this.setShowTipoResiduo(this.residuosTipoFilter,true);
      this.setShowTipoDisposicion(this.residuosTipoFilter,false);
      
      this.setShowContracts(this.residuosTipoTratamientoFilter,true)
      this.setFilterResiduos(this.residuosTipoTratamientoFilter,this.dataResiduos);
      this.setFilterPlanta(this.residuosTipoTratamientoFilter,this.dataPlanta);
      this.setFilterDesde(this.residuosTipoTratamientoFilter,this.dateLast);
      this.setFilterHasta(this.residuosTipoTratamientoFilter,this.dateNow);
      this.setFilterTipoDisposicion(this.residuosTipoTratamientoFilter,this.dataTipoDisposicion);
      this.setShowDisposition(this.residuosTipoTratamientoFilter,false);
      this.setShowTipoResiduo(this.residuosTipoTratamientoFilter,false);
      this.setShowTipoDisposicion(this.residuosTipoTratamientoFilter,true);

      this.setShowContracts(this.residuosGestionadosAnioFilter,true)
      this.setFilterResiduos(this.residuosGestionadosAnioFilter,this.dataResiduos);
      this.setFilterPlanta(this.residuosGestionadosAnioFilter,this.dataPlanta);
      this.setFilterDesde(this.residuosGestionadosAnioFilter,this.dateLast);
      this.setFilterHasta(this.residuosGestionadosAnioFilter,this.dateNow);
      this.setFilterTipoDisposicion(this.residuosGestionadosAnioFilter,this.dataTipoDisposicion);
      this.setShowDisposition(this.residuosGestionadosAnioFilter,false);
      this.setShowTipoResiduo(this.residuosGestionadosAnioFilter,true);
      this.setShowTipoDisposicion(this.residuosGestionadosAnioFilter,false);

      this.setFilterDesde(this.residuosServicesFilter,this.dateLast);
      this.setFilterHasta(this.residuosServicesFilter,this.dateNow);
      
    }
    , error=>{ console.log(error); this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,}); }) 
}


loadResiduosPlantaChart(container: any = null, tableContainer: any = null, filters: any = null) {
  
  if (this.currentChart && container) {
    container.clear();
  }
  if (this.currentTable && tableContainer) {
    tableContainer.clear();
  }

  const f = filters || this.chartFilters['residuosPlanta'];
  container = container || this.residuosPlantaChartContainer;
  tableContainer = tableContainer || this.residuosPlantaTablaContainer;

  this.currentChart = this.createHorizontalChart(container, 'disposicion', 'Cargando...', ['col-lg-12']);
  this.currentTable = this.createTable(tableContainer, '', [], ['col-lg-12']);

  this.currentChart.style = {
    ...this.currentChart.style,
    titleAlignment: 'center',
    titlePosition: { x: 50, y: 20 }, // Ajustar posición del título
    titlePadding: 20,
    title: {
      text: 'Cargando...',
      textAlign: 'center',
      width: '100%'
    }
  };
  
  this.currentTable.displayAllColumns = true;
  this.currentTable.paddingLeft = '80px';
  this.currentTable.paddingRight = '170px';
  this.currentTable.fontSize = 10;
  this.currentTable.setDisplayFooter(true);

  this.dashboard.getresiduosPlanta(f.contracts, f.desde, f.hasta, f.planta, f.tipoResiduo, f.tipoDisposicion)
    .subscribe(
      data => {
        let table = data['table'];
        
        
        this.currentChart.style.legend = true;
        this.currentChart.style.legendTitle = 'Residuos';
        this.currentChart.style.yAxisLabel = 'Planta';
        this.currentChart.style.xAxisLabel = 'Kilos';
        this.currentChart.title = 'Residuos gestionados';
        this.currentChart.setDataSource(data['chart']);
        this.currentChart.xAxisTickFormatting = this.formatNumberSeparator;
                
        var total = table.map(t => t.Kilos).reduce((acc, value) => acc + value, 0);
        table = this.formatDataTable(table, 'Kilos');
        this.titles.residuosPlanta = data['title'];
        
        this.currentTable.setDataSource(table);
        total = this.formatNumberSeparator(total);
        this.currentTable.setFooters(['Total', '', '', total]);
      },
      error => {
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde", {timeout: 5000});
      }
    );
}


loadResiduosTipoChart(container: any = null, tableContainer: any = null, filters: any = null) {
  console.log('Loading tipo chart with filters:', filters);

  
  container = this.residuosTipoChartContainer;
  tableContainer = this.residuosTipoTablaContainer;

  
  container.clear();
  tableContainer.clear();
  
  this.currentTipoChart = this.createGroupedVerticalChart(container, 'residuos', '', ['col-lg-12']);
  this.currentTipoTable = this.createTable(tableContainer, '', [], ['col-lg-12']);

  const f = filters || this.chartFilters['residuosTipo'];
  
  this.currentTipoTable.displayAllColumns = true;
  this.currentTipoTable.paddingLeft = '80px';
  this.currentTipoTable.paddingRight = '170px';
  this.currentTipoTable.fontSize = 10;
  this.currentTipoTable.setDisplayFooter(true);

  this.dashboard.getresiduosTipo(f.contracts, f.desde, f.hasta, f.planta, f.tipoResiduo, f.tipoDisposicion)
    .subscribe(
      data => {
        
        this.currentTipoChart.style.legend = true;
        this.currentTipoChart.style.showXAxisLabel = true;
        this.currentTipoChart.style.xAxisLabel = 'Residuos';
        this.currentTipoChart.style.showYAxisLabel = true;
        this.currentTipoChart.style.yAxisLabel = 'Kilos';
        this.currentTipoChart.title = 'Tipo de Residuos gestionados';
        this.currentTipoChart.setDataSource(data['chart']);
        this.currentTipoChart.yAxisTickFormatting = this.formatNumberSeparator;
        
        let table = data['table'];
        var total = table.map(t => t.Kilos).reduce((acc, value) => acc + value, 0);
        table = this.formatDataTable(table, 'Kilos');
        this.titles.residuosTipo = data['title'];

        this.currentTipoTable.setDataSource(table);
        total = this.formatNumberSeparator(total);
        this.currentTipoTable.setFooters(['Total', '', total]);

        console.log('Tipo chart and table updated successfully');
      },
      error => {
        console.log('Error loading data:', error);
        this.notify.warning("Conexion no disponible, Intente mas tarde", {timeout: 5000});
      }
    );
}

loadResiduosTipoTratamientoChart(container: any = null, tableContainer: any = null, filters: any = null) {
  console.log('Loading tipo tratamiento chart with filters:', filters);

  container = this.residuosTipoTratamientoChartContainer;
  tableContainer = this.residuosTipoTratamientoTablaContainer;

  container.clear();
  tableContainer.clear();

  this.noSpeciesGroups = false;
  this.maxPlaguePresence = 0;

  const f = filters || this.chartFilters['residuosTipoTratamiento'];

  this.currentTipoTratamientoChart = this.createStackedVerticalChart(container, 'tipoResiduo', '', ['col-lg-12']);
  this.currentTipoTratamientoTable = this.createTable(tableContainer, '', [], ['col-lg-12']);

  this.currentTipoTratamientoTable.displayAllColumns = true;
  this.currentTipoTratamientoTable.paddingLeft = '80px';
  this.currentTipoTratamientoTable.paddingRight = '170px';
  this.currentTipoTratamientoTable.fontSize = 10;
  this.currentTipoTratamientoTable.setDisplayFooter(true);

  this.dashboard.getresiduosTipoTratamiento(f.contracts, f.desde, f.hasta, f.planta, f.tipoResiduo, f.tipoDisposicion)
    .subscribe(
      data => {
        
        this.currentTipoTratamientoChart.style.legend = true;
        this.currentTipoTratamientoChart.style.legendTitle = 'Planta';
        this.currentTipoTratamientoChart.style.yAxisLabel = 'Kilos';
        this.currentTipoTratamientoChart.style.xAxisLabel = 'Tipo disposición';
        this.currentTipoTratamientoChart.style.showXAxisLabel = true;
        this.currentTipoTratamientoChart.style.showYAxisLabel = true;
        this.currentTipoTratamientoChart.title = 'Residuos gestionados';
        this.currentTipoTratamientoChart.setDataSource(data['chart']);
        this.currentTipoTratamientoChart.yAxisTickFormatting = this.formatNumberSeparator;
        
        let table = data['table'];
        var total = table.map(t => t.Kilos).reduce((acc, value) => acc + value, 0);
        table = this.formatDataTable(table, 'Kilos');
        this.titles.residuosTipoTratamiento = data['title'];

        this.currentTipoTratamientoTable.setDataSource(table);
        total = this.formatNumberSeparator(total);
        this.currentTipoTratamientoTable.setFooters(['Total', '', total]);

        console.log('Tipo tratamiento chart and table updated successfully');
      },
      error => {
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde", {timeout: 5000});
      }
    );
}

loadResiduosGestionadoAnioChart(container: any = null, tableContainer: any = null, filters: any = null) {
  console.log('Loading gestionado anio chart with filters:', filters);
  
  container = this.residuosGestionadoAnioChartContainer;
  tableContainer = this.residuosGestionadoAnioTablaContainer;

  container.clear();
  tableContainer.clear();

  this.noSpeciesGroups = false;
  this.maxPlaguePresence = 0;

  const f = filters || this.chartFilters['residuosGestionadosAnio'];

  this.currentGestionadoAnioChart = this.createGroupedVerticalChart(container, 'periodo', '', ['col-lg-12']);
  this.currentGestionadoAnioTable = this.createTable(tableContainer, '', [], ['col-lg-12']);

  this.currentGestionadoAnioTable.displayAllColumns = true;
  this.currentGestionadoAnioTable.paddingLeft = '80px';
  this.currentGestionadoAnioTable.paddingRight = '170px';
  this.currentGestionadoAnioTable.fontSize = 10;
  this.currentGestionadoAnioTable.setDisplayFooter(true);

  this.dashboard.getresiduosGestionadosAnio(f.contracts, f.desde, f.hasta, f.planta, f.tipoResiduo, f.tipoDisposicion)
    .subscribe(
      data => {
        
        this.currentGestionadoAnioChart.style.legend = true;
        this.currentGestionadoAnioChart.style.showXAxisLabel = true;
        this.currentGestionadoAnioChart.style.xAxisLabel = 'Período';
        this.currentGestionadoAnioChart.style.showYAxisLabel = true;
        this.currentGestionadoAnioChart.style.yAxisLabel = 'Kilos';
        this.currentGestionadoAnioChart.title = 'Residuos gestionados por año';
        this.currentGestionadoAnioChart.setDataSource(data['chart']);
        this.currentGestionadoAnioChart.yAxisTickFormatting = this.formatNumberSeparator;
        
        let table = data['table'];
        var total = table.map(t => t.Kilos).reduce((acc, value) => acc + value, 0);
        table = this.formatDataTable(table, 'Kilos');
        this.titles.residuosGestionadoAnio = data['title'];

        this.currentGestionadoAnioTable.setDataSource(table);
        total = this.formatNumberSeparator(total);
        this.currentGestionadoAnioTable.setFooters(['Total', '', total]);

        console.log('Gestionado anio chart and table updated successfully');
      },
      error => {
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde", {timeout: 5000});
      }
    );
}



loadResiduoservicios(filters = null,servicesPage){
  
  var f = filters!=null ? filters : this.chartFilters['residuosServices'];
  this.showsServicesTable = 0;
  
  this.servicesPage = servicesPage;
  var t = this.servicesOptions;
  
  this.loadingServices = 0;
  this.dashboard.getResidusoServicios(f.contracts,f.desde,f.hasta).subscribe(
    data => {
      console.log(data['datos'])
      this.residuosServicesList = data['datos'];
      //dadabikd_pesaje
      this.residuosServicesList = this.formatDataTable(this.residuosServicesList,'dadabikd_pesaje'); 
      
      this.listAllServices = new MatTableDataSource(this.residuosServicesList);    
      this.listAllServices.sort = this.allServicesSort;
      this.listAllServices.paginator = this.paginatorAllResiduos;
      this.showsServicesTable = 1;
       
      this.listAllServices.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'fecha': return new Date(item.fecha);     
          default: return item[property];
        }
      }; 

      this.loadingAllServices = 1;     
      if(this.listAllServices.length == 0){ this.listAllServices.length = 0; this.loadingServices = 2;  }     
    
      this.loadingServices = 1;     
      if(this.listServicios.length == 0){ this.loadingServices = 2;  }
      
    }, error =>{
      this.loadingServices = 3;
      this.notify.warning('Error al cargar informacion de Gestión de Residuos.');
    }
  );

  //Si se aplican filtros externos (modal) no se refrescan servicios
  if(filters!=null) return;
  
}


formatDataTable(table,column){
  for (var arrayIndex in table) { 
    table[arrayIndex][column] = this.formatNumberSeparator(table[arrayIndex][column]);
  }
  return table;
}

exportResiduosServicios(filters = null, page = null) {
    
    if(this.residuosServicesList.length==0){
      alert('No hay servicios para exportar');
      return;
    }
    const filename = 'Residuos Servicios';
    const headers = [
      'FECHA VISITA',
      'PATENTEE RETIRO',
      'CONDUCTOR',
      'N° GUIA DESPACHO',
      'FECHA INGRESO RELLENO',
      'N° INGRESO RELLENO',
      'KILOS INGRESO',
      'DISPOSICION FINAL / VALORIZACION',
      'PATENTE DISPOSICION',
      'TIPO RESIDUO',
      'NOMBRE DISPOSICION FINAL',
      'NOMBRE CLIENTE',
      'TIPO VISITA'
  ];
  let dataServices = [];
  for(let i=0;i<this.residuosServicesList.length;i++){
    
    dataServices.push({dadabikd_date:this.residuosServicesList[i]['dadabikd_date'],dadabikd_patente:this.residuosServicesList[i]['dadabikd_patente'],
      dadabikd_trabajador_disposicion:this.residuosServicesList[i]['dadabikd_trabajador_disposicion'],
      dadabikd_numero_guia_despacho:this.residuosServicesList[i]['dadabikd_numero_guia_despacho'],dadabikd_fecha_ingreso_relleno:this.residuosServicesList[i]['dadabikd_fecha_ingreso_relleno'],
      dadabikd_numero_ticket_pesajex:this.residuosServicesList[i]['dadabikd_numero_ticket_pesaje'],dadabikd_pesaje:this.residuosServicesList[i]['dadabikd_pesaje'],
      dadabikd_tipo_disposicion:this.residuosServicesList[i]['dadabikd_tipo_disposicion'],dadabikd_patente_disposicion:this.residuosServicesList[i]['dadabikd_patente_disposicion'],
      dadabikd_tipo_residuo:this.residuosServicesList[i]['dadabikd_tipo_residuo'],
      dadabikd_disposicion_final:this.residuosServicesList[i]['dadabikd_disposicion_final'],
      clie_name:this.residuosServicesList[i]['cont_name'],
      vity_name:this.residuosServicesList[i]['vity_name'],
    })
  }


  this.excelService.jsonToExcel({ headers, data: dataServices, filename });

}



  /**
   * Dada una actualizacion en el componente de filtros, actualiza las variables locales.
   * Si el cambio en los filtros ocurren en el contrato, se actualizan todos los graficos 
   * mediante updateContracts(), sino, se actualiza solo el grafico correspondiente al 
   * filtro que lanza el evento
   * @param chartGroup : string - indica el grafico o grupo de graficos que lanza el evento
   * @param event : object - indica el estado actual del filtro que lanzó el evento
   * @return none
   */
  updateFilters(chartGroup,event){
    
    this.chartFilters[chartGroup] = event.filters;
    let carga_contrato = false;
     
    if(carga_contrato){
      this.aplyFiltersToAllCharts();  
    }else{
      
      if(chartGroup=='residuosPlanta'){
        this.residuosPlantaChartContainer.clear(); 
        this.residuosPlantaTablaContainer.clear();
        this.loadResiduosPlantaChart();
      }else if(chartGroup=='residuosTipo'){
        this.residuosTipoChartContainer.clear(); 
        this.residuosTipoTablaContainer.clear();
        this.loadResiduosTipoChart();
      }else if(chartGroup=='residuosTipoTratamiento'){
        this.residuosTipoTratamientoChartContainer.clear(); 
        this.residuosTipoTratamientoTablaContainer.clear();
        this.loadResiduosTipoTratamientoChart();
      }else if(chartGroup=='residuosGestionadosAnio'){
        this.residuosGestionadoAnioChartContainer.clear();
        this.residuosGestionadoAnioTablaContainer.clear(); 
        this.loadResiduosGestionadoAnioChart()
      }else if(chartGroup=='residuosServices'){
        //this.residuosServicesChartContainer.clear(); 
        this.loadResiduoservicios(null,1)
      }
    }
  } 
  

  updateFilterSearch(filter,search){
    
    if( filter != 'residuosPlanta' ) this.residuosPlantaFilter.filterContracts(search);
    if( filter != 'residuosTipo' ) this.residuosTipoFilter.filterContracts(search);
    if( filter != 'residuosTipoTratamiento' ) this.residuosTipoTratamientoFilter.filterContracts(search);
    if( filter != 'residuosGestionadosAnio' ) this.residuosGestionadosAnioFilter.filterContracts(search);
    if( filter != 'residuosServices' ) this.residuosServicesFilter.filterContracts(search);
  }

  isArrayEmpty( toCheck ){
    if(Array.isArray(toCheck)){
      if( toCheck.length>0 ){
        return false;
      }
    } 
    return true;
  }

    /**
   * Dada una actualizacion en el componente de filtros de tareas, actualiza las variables 
   * locales y luego actualiza la tabla mediante loadTasks()
   * @param event : object - indica el estado actual del filtro
   * @return none
   */
 



    /**
   * Establece la opcion seleccionada en todos los filtros de graficos.
   * Luego llama a la funcion aplyFiltersToAllCharts() para actualizar los resultados
   * en consecuencia con el nuevo contrato seleccionado
   * @param contracts int contrato seleccionado
   * @return none
   */
  updateContracts(contracts, type = 'SET_SELECTED' ){
    for( var key in this.chartFilters ){
      //this.chartFilters[key]['contracts'] = contracts;
    }
    
    if( type == 'SET_DEFAULT' ){
     
    }else{
     
    }
  }

  graphicGestionResiduos(){
    $('.app_menu').css({"max-height":"2700px"});
  }


  clearReferences(){
   
    this.residuosPlantaChartContainer.clear();
    this.residuosPlantaTablaContainer.clear();

    this.residuosTipoChartContainer.clear();
    this.residuosTipoTablaContainer.clear();

    this.residuosTipoTratamientoChartContainer.clear();
    this.residuosTipoTratamientoTablaContainer.clear();

    this.residuosGestionadoAnioChartContainer.clear();
    this.residuosGestionadoAnioTablaContainer.clear();
   
  }


  getContractNameById(contractId){
    var text = 'Ninguno';
    if( !(contractId > 0) ) return text;
    this.contratosTodos.map((c)=>{
      if( c.value == contractId ) text = c.cont_name;
    });
    return text;
  }

  // @HostListener('complete', ['$event']) 


  
  onChangeFilter(event)
  {
    if( event.eventTrigger == 'contract' ){
      this.updateContracts(event.filters.contracts);
    }
  }

  addDaysDate(date,dias){

    date = date.replaceAll("/","-");

    date = date.replaceAll("/","-");

    let  dateSplit = date.split('-')

    if(dateSplit[0].length!=4){
        date =  date.split('-').reverse().join('-');
    }

    var fecha = new Date(date+" 12:00");
    //var dias = 1; // Número de días a agregar
    fecha.setDate(fecha.getDate() + dias);

    let year =fecha.getFullYear();
    let day = fecha.getDate();
    let month = fecha.getMonth()+1;
    let dayStr = ''+day;
    let monthStr = ''+month;

    if(day<10) dayStr='0'+day;
    if(month<10)monthStr='0'+month;

    return  year+"-"+monthStr+"-"+dayStr;
}

  //Actualizacion de fechas en todos los filtros

  onGlobalFromDateChange(event: any) {
    try {
      const selectedDate = new Date(event.value);
      selectedDate.setHours(0, 0, 0, 0); // Establecer hora a inicio del día
      
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
      const day = String(selectedDate.getDate()).padStart(2, '0');
      
      this.globalFromDateValue = `${year}-${month}-${day}`;
    } catch (error) {
      console.error('Error setting global from date:', error);
    }
  }

  onGlobalToDateChange(event: any) {
    try {
        if (!event.value) return;

        // Convertir a Date sin modificar la zona horaria
        const selectedDate = new Date(event.value);
        
        // Ajustar la fecha a UTC si el Datepicker muestra un día antes
        selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());

        // Asignar directamente como objeto Date
        this.globalToDateValue = selectedDate.toISOString().split('T')[0];

    } catch (error) {
        console.error('Error setting global to date:', error);
    }
  }

  public initialDateFilter = (date: Date): boolean => {
    const minDate = new Date('2023-05-12');
    return date >= minDate;
  };

  //Aplicación de fechas globales

  applyGlobalFilters() {
    this.clearReferences();

    if (this.residuosPlantaFilter) {
      this.residuosPlantaFilter.selectedDesde = this.globalFromDateValue;
      this.residuosPlantaFilter.selectedHasta = this.globalToDateValue;
      this.residuosPlantaFilter.selectedDesdeMas = this.addDaysDate(this.globalFromDateValue, 1);
      this.residuosPlantaFilter.selectedHastaMas = this.addDaysDate(this.globalToDateValue, 1);
      this.residuosPlantaFilter.updateLastSearch();
    }
  
    if (this.residuosTipoFilter) {
      this.residuosTipoFilter.selectedDesde = this.globalFromDateValue;
      this.residuosTipoFilter.selectedHasta = this.globalToDateValue;
      this.residuosTipoFilter.selectedDesdeMas = this.addDaysDate(this.globalFromDateValue, 1);
      this.residuosTipoFilter.selectedHastaMas = this.addDaysDate(this.globalToDateValue, 1);
      this.residuosPlantaFilter.updateLastSearch();
    }
  
    if (this.residuosTipoTratamientoFilter) {
      this.residuosTipoTratamientoFilter.selectedDesde = this.globalFromDateValue;
      this.residuosTipoTratamientoFilter.selectedHasta = this.globalToDateValue;
      this.residuosTipoTratamientoFilter.selectedDesdeMas = this.addDaysDate(this.globalFromDateValue, 1);
      this.residuosTipoTratamientoFilter.selectedHastaMas = this.addDaysDate(this.globalToDateValue, 1);
      this.residuosPlantaFilter.updateLastSearch();
    }
  
    if (this.residuosGestionadosAnioFilter) {
      this.residuosGestionadosAnioFilter.selectedDesde = this.globalFromDateValue;
      this.residuosGestionadosAnioFilter.selectedHasta = this.globalToDateValue;
      this.residuosGestionadosAnioFilter.selectedDesdeMas = this.addDaysDate(this.globalFromDateValue, 1);
      this.residuosGestionadosAnioFilter.selectedHastaMas = this.addDaysDate(this.globalToDateValue, 1);
      this.residuosPlantaFilter.updateLastSearch();
    }
  
    Object.keys(this.chartFilters).forEach(key => {
      this.chartFilters[key].desde = this.globalFromDateValue;
      this.chartFilters[key].hasta = this.globalToDateValue;
    });
  
    this.aplyFiltersToAllCharts();

  }

  applyGlobalDates() {
    if (!this.globalFromDateValue || !this.globalToDateValue) {
      Swal.fire({
        title: 'Error',
        text: 'Por favor seleccione ambas fechas',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }else{
      Swal.fire({
        title: 'Aplicando Filtros Globales...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 5000,
        onOpen: () => {
          Swal.showLoading();
        }
      }).then(() => {
          Swal.fire({ 
              title: 'Filtros Aplicados',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false
          });
      });
    }
    
    this.applyGlobalFilters();
  }

  clearGlobalDates() {

    // Mantener las fechas iniciales
    this.globalFromDateValue = this.dateLast;
    this.globalToDateValue = this.dateNow;

    // Reiniciar chartFilters con las fechas por defecto en lugar de strings vacíos
    this.chartFilters = {
      'residuosPlanta': {
        'contracts': '',
        'desde': this.dateLast, // Usar fecha por defecto
        'hasta': this.dateNow,  // Usar fecha por defecto
        'tipoResiduo': [],
        'planta': [],
        'tipoDisposicion': []
      },
      'residuosTipo': {
        'contracts': '',
        'desde': this.dateLast,
        'hasta': this.dateNow,
        'tipoResiduo': []
      },
      'residuosTipoTratamiento': {
        'contracts': '',
        'desde': this.dateLast,
        'hasta': this.dateNow,
        'tipoDisposicion': []
      },
      'residuosGestionadosAnio': {
        'years': [],
        'months': [],
        'contracts': '',
        'desde': this.dateLast,
        'hasta': this.dateNow,
        'tipoResiduo': []
      },
      'residuosServices': {
        'contracts': '',
        'desde': this.dateLast,
        'hasta': this.dateNow
      }
    };

    // Actualizar los filtros individuales con las fechas por defecto
    const filters = [
      this.residuosPlantaFilter,
      this.residuosTipoFilter, 
      this.residuosTipoTratamientoFilter,
      this.residuosGestionadosAnioFilter,
      this.residuosServicesFilter
    ];

    filters.forEach(filter => {
      if (filter) {
        filter.cleanFilters();
        filter.selectedDesde = this.dateLast;
        filter.selectedHasta = this.dateNow;
        filter.selectedDesdeMas = this.addDaysDate(this.dateLast, 1);
        filter.selectedHastaMas = this.addDaysDate(this.dateNow, 1);
      }
    });
    
    Swal.fire({
      title: 'Quitando Filtros Globales...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000,
      onOpen: () => {
        Swal.showLoading();
      }
    }).then(() => {
      Swal.fire({ 
        title: 'Filtros Reiniciados',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false
      });
    });
    
    this.clearReferences();
    this.aplyFiltersToAllCharts(true);
  }
}