import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { FormControl } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { isArray } from 'util';

@Component({
    selector: 'dashboard-filter-kdm',
    templateUrl: './dashboard-filter.component.html',
    styleUrls: ['./dashboard-filter.component.css']
})

export class DashboardFilterKdmComponent implements OnInit 
{
    @Input() multiMonth:string = 'false';
    @Input() multiYear:string = 'false';
    @Input() multiContract:string = 'false';
    @Input() multiDisposicion:string = 'false';
    @Input() multiTipoResiduos:string = 'false';
    @Input() multiTipoDisposicion:string = 'false';
    @Input() multiProperty:string = 'false';
    @Input() hideMonth = false;
    @Input() hideYear = false;
    @Input() hideDesde = false;
    @Input() hideHasta = false;
    @Input() hideProperty = true;
    @Input() showContracts = false;
    @Input() showDisposicion = false;
    @Input() showTipoResiduo = false;
    @Input() showTipoDisposicion = false;
    @Input() stickyYear = false;
    @Input() stickyYearNotificationText = 'Se establecio el año actual por defecto. Para filtrar por año movil desmarque primero los meses seleccionados';
    @Input() noButtons = false;
    @Input() propertyLabel :string = 'Propiedad';
    
    @Output() onChange = new EventEmitter();
    @Output() onContractSearch = new EventEmitter();
    @Output() onDisposicionSearch = new EventEmitter();
    @Output() onResiduosearch = new EventEmitter();
    @Output() onTipoDisposicionsearch = new EventEmitter();
    

    @Output('aplyFilter') aplyFilterEvent = new EventEmitter();
    @Output('removeFilter') removeFilterEvent = new EventEmitter();

    @HostBinding('style.width') width = "100%";
    //public date: Date;

    // selectedContract=null;
    data;
    allContracts;
    allTipoResiduos;
    allDisposicion;
    allTipoDisposicion;
    selectedYears = [];
    selectedMonths = [];
    selectedContracts = [];
    selectedDesde = '';
    selectedHasta = '';
    selectedDesdeMas = '';
    selectedHastaMas = '';
    selectedTipoDisposicion ='';
    selectedTipoResiduos = '';
    selectedDisposicion = '';
    public initialDate = '2023-05-12';
    yearControl;
    yearOptions;
    monthOptions;
    monthControl;
    contractControl;
    desdeControl;
    hastaControl;
    tipoResiduosControl;
    disposicionControl;
    tipoDisposicionControl;
    date;
    
    contractOptions;
    contractSearch = '';
    tipoDispocisionSearch = '';
    tipoResiduosSearch = '';
    clients = [];
    disposicionSearch = '';
    
    tipoDisposicionSearch='';

    public selectDates = {
        detection: ''
        , verification: ''
      };
    lastSearch = {
        'months' : [],
        'years' : [],
        'contracts' : [],
        'properties' : [],
        'tipoResiduo':'',
        'tipoDisposicion':'',
        'disposicion':'',
        'desde':'',
        'hasta':''
        
    }
    defaultSearch = {
        'months' : [],
        'years' : [],
        'contracts' : [],
        'tipoResiduo':'',
        'tipoDisposicion':'',
        'disposicion':'',
        'desde':'',
        'hasta':''
    }
    propertyControl: any;
    selectedProperties: any;
    


    constructor(private notify:SnotifyService)
    {
  


        var currentYear = new Date().getFullYear();
        this.yearOptions = [];
        for( let year=2015; year <= (currentYear+5); year++ ){
            this.yearOptions.push({id:year,value:year});
        }
        this.data = {
            'month' :  [
                {id: 1, value: 'Enero'},
                {id: 2, value: 'Febrero'},
                {id: 3, value: 'Marzo'},
                {id: 4, value: 'Abril'},
                {id: 5, value: 'Mayo'},
                {id: 6, value: 'Junio'},
                {id: 7, value: 'Julio'},
                {id: 8, value: 'Agosto'},
                {id: 9, value: 'Septiembre'},
                {id: 10, value: 'Octubre'},
                {id: 11, value: 'Noviembre'},
                {id: 12, value: 'Diciembre'},
              ]
            , 'year' : this.yearOptions
            , 'contract' : []
            , 'property' : []
            , 'tipoResiduo' : []
            , 'planta' : []
            , 'tipoDisposicion' : []
            , 'desde':''
            , 'hasta':''
            

        };

        this.setDefaultYears( this.multiYear ? [] : '' )
        this.setDefaultMonths( this.multiMonth ? [] : '' )
        this.setDefaultContract( this.multiContract ? [] : '' )
        this.setDefaultTipoResiduo ('')
        this.setDefaultTipoDisposicion ('')
        this.setDefaultDisposicion ('')
        //this.setDefaultProperty( this.multiProperty ? [] : '' )

        this.yearControl = new FormControl();
        this.monthControl = new FormControl();
        this.contractControl = new FormControl();
        this.propertyControl = new FormControl();
        this.desdeControl = new FormControl();
        this.hastaControl = new FormControl();
        this.tipoResiduosControl = new FormControl();
        this.disposicionControl = new FormControl();
        this.tipoDisposicionControl = new FormControl();
        
    }

    setData(type:string,data)
    {
        
        if(type in this.data){
            this.data[type] = data;
            if(type=='contract') this.allContracts = data;
            if(type=='tipoDisposicion') this.allTipoDisposicion = data;
            if(type=='planta') this.allDisposicion = data;
            if(type=='tipoResiduo') this.allTipoResiduos = data;
            if(type=='desde') this.selectedDesde = data;
            if(type=='desde') this.selectedDesdeMas = this.addDaysDate(data,1);
            if(type=='hasta') this.selectedHasta = data;
            if(type=='hasta') this.selectedHastaMas = this.addDaysDate(data,1);
            
        }
    }
    setShowContracts(value)
    {
       this.showContracts = value;
    }
    setShowDisposition(value)
    {
       this.showDisposicion = value;
    }
    setShowTipoResiduo(value)
    {
       this.showTipoResiduo = value;
    }
    setShowTipoDisposicion(value)
    {
       this.showTipoDisposicion = value;
    }


    
    //# Funciones para setear Seleccion
    setSelectedContract(contracts)
    {
        if( this.isMultiple('contract') ){
            this.contractControl.setValue(contracts);
        }
        this.selectedContracts = contracts;
    }
    setSelectedTipoResiduo(tipoResiduos)
    {
        if( this.isMultiple('tipoResiduo') ){
            this.tipoResiduosControl.setValue(tipoResiduos);
        }
        this.selectedTipoResiduos = tipoResiduos;
    }
    setSelectedTipoDisposicion(tipoDisposicion)
    {
        if( this.isMultiple('tipoDisposicion') ){
            this.tipoDisposicionControl.setValue(tipoDisposicion);
        }
        this.selectedTipoDisposicion = tipoDisposicion;
    }
    setSelectedDisposicion(disposicion)
    {
        if( this.isMultiple('tipoDisposicion') ){
            this.disposicionControl.setValue(disposicion);
        }
        this.selectedDisposicion = disposicion;
    }
    
    setSelectedMonths(months)
    {
        if( this.isMultiple('month') ){
            this.monthControl.setValue(months);
        }
        this.selectedMonths = months; 
    }


    setSelectedYears(years)
    {
        if( this.isMultiple('year') ){
            this.yearControl.setValue(years);
        }
        this.selectedYears = years;
    }
    //#

    //# Funciones para controlar el comportamiento inicial del filtro
    setDefaultMonths(months)
    {
        this.setSelectedMonths(months) 
        this.lastSearch.months = months;
        this.defaultSearch.months = months;
    }

    setDefaultYears(years)
    {
        this.setSelectedYears(years);
        this.lastSearch.years = years;
        this.defaultSearch.years = years;    
    }
    setDefaultContract(contracts)
    {
        
        this.setSelectedContract(contracts);
        this.lastSearch.contracts = contracts;
        this.defaultSearch.contracts = contracts;
    }

    setDefaultTipoResiduo(tipoResiduos)
    {
        
        this.setSelectedTipoResiduo(tipoResiduos);
        this.lastSearch.tipoResiduo = tipoResiduos;
        this.defaultSearch.tipoResiduo = tipoResiduos;
    }

    setDefaultTipoDisposicion(tipoDisposicion)
    {
        
        this.setSelectedTipoDisposicion(tipoDisposicion);
        this.lastSearch.tipoDisposicion = tipoDisposicion;
        this.defaultSearch.tipoDisposicion = tipoDisposicion;
    }
    setDefaultDisposicion(disposicion)
    {
        
        this.setSelectedContract(disposicion);
        this.lastSearch.disposicion = disposicion;
        this.defaultSearch.disposicion = disposicion;
    }



    onMonthChange(event)
    {
        this.selectedMonths = event.value;
        if ( this.stickyYear && this.onlyMonthSelected() ){
            this.setCurrentYear();
        }
        this.emit('month');
    }
    
    onYearChange(event)
    {
        this.selectedYears = event.value; 
        if ( this.stickyYear && this.onlyMonthSelected() ){
            this.setCurrentYear();
            this.notify.info(this.stickyYearNotificationText, {timeout:6000} );
        }
        this.emit('year');
    }

    onContractChange(event)
    {
        this.selectedContracts = event.value;
        this.emit('contract'); 
    }

    
    verificationDateChange(event) {
        this.selectDates['verification'] = (event.value.toLocaleDateString().split('-')).reverse().join('-');
        this.selectedDesde= (event.value.toLocaleDateString().split('-')).reverse().join('-');
        //this.filters();
      }
    
    initialDateFilter = (d: Date): boolean => {
    if (!this.initialDate) return true;
    let year = d.getFullYear();
    let month = ("0" + (d.getMonth() + 1)).slice(-2)
    let day = ("0" + d.getDate()).slice(-2);
    this.date = `${year}-${month}-${day}`;
    return this.date >= this.initialDate;
    }

    onDisposicionChange(event)
    {
        this.selectedDisposicion = event.value;
        this.emit('planta'); 
    }

    
    onDesdeChange(event)
    {
      
        try{

            //this.selectedDesde = (event.value.toLocaleDateString().split('-')).reverse().join('-');
            this.selectedDesde = this.addDaysDate(event.value.toLocaleDateString(),0);

            this.selectedDesdeMas =  this.addDaysDate(this.selectedDesde,1);
   
            
        }catch{
            
        }
        this.emit('desde'); 
    }

    addDaysDate(date,dias){

        date = date.replaceAll("/","-");

        let  dateSplit = date.split('-')

        if(dateSplit[0].length!=4){
            date =  date.split('-').reverse().join('-');
        }

        var fecha = new Date(date+" 12:00");
        //var dias = 1; // Número de días a agregar
        fecha.setDate(fecha.getDate() + dias);

        let year =fecha.getFullYear();
        let day = fecha.getDate();
        let month = fecha.getMonth()+1;
        let dayStr = ''+day;
        let monthStr = ''+month;

        if(day<10) dayStr='0'+day;
        if(month<10)monthStr='0'+month;

        return  year+"-"+monthStr+"-"+dayStr;
    }
    onHastaChange(event)
    {
        try{
            //this.selectedHasta = (event.value.toLocaleDateString().split('-')).reverse().join('-');
            this.selectedHasta =  this.addDaysDate(event.value.toLocaleDateString(),0);
            
            this.selectedHastaMas =  this.addDaysDate(this.selectedHasta,1);
           
        }catch{
        }
        this.emit('hasta'); 
    }
  
    onResiduosChange(event)
    {
        this.selectedTipoResiduos = event.value;
        this.emit('tipoResiduo'); 
    }
    onTipoDisposicionChange(event)
    {
        this.selectedTipoDisposicion = event.value;
        this.emit('tipoDisposicion'); 
    }

    onPropertyChange(event)
    {
        this.selectedProperties = event.value;
        this.data['contract'] = this.getContractsFilteredByProperty();
        var _this = this;
        let keepSelected = this.selectedContracts.filter(function(el){
            let finded = false;
            for(let contract of _this.data['contract']){
                if(contract.id==el){
                    finded = true;
                }
            }
            return finded;
        });
        this.setSelectedContract(keepSelected);
        this.emit('property'); 
    }

    getContractsFilteredByProperty()
    {
        
        let properties = Array.isArray(this.selectedProperties) ? this.selectedProperties : [this.selectedProperties];
        
        var relatedContracts :any= this.getContractsFromProperties(properties);

        let filteredContracts = relatedContracts.length>0
                        ? this.allContracts.filter(function(el){ return relatedContracts.includes(el.id); })
                        : this.allContracts;
        return filteredContracts;
    }
    getTipoDisposicionFilteredByProperty()
    {
        let properties = Array.isArray(this.selectedProperties) ? this.selectedProperties : [this.selectedProperties];
        var relatedTipoDisposicion :any= this.getTipoDisposicionFromProperties(properties);
        let filteredTipoDisposicion = relatedTipoDisposicion.length>0
                        ? this.allTipoDisposicion.filter(function(el){ return relatedTipoDisposicion.includes(el.id); })
                        : this.allTipoDisposicion;
        return filteredTipoDisposicion;
    }

    getDisposicionFilteredByProperty()
    {
        
        let properties = Array.isArray(this.selectedProperties) ? this.selectedProperties : [this.selectedProperties];
        var relatedDisposicion :any= this.getDisposicionFromProperties(properties);
        
        let filteredDisposicion = relatedDisposicion.length>0
                        ? this.allDisposicion.filter(function(el){ return relatedDisposicion.includes(el.id); })
                        : this.allDisposicion;
        return filteredDisposicion;
    }

    getTipoResiduosFilteredByProperty()
    {
        let properties = Array.isArray(this.selectedProperties) ? this.selectedProperties : [this.selectedProperties];
        var relatedTipoResiduos :any= this.getTipoResiduosFromProperties(properties);
        let filteredTipoResiduos = relatedTipoResiduos.length>0
                        ? this.allTipoResiduos.filter(function(el){ return relatedTipoResiduos.includes(el.id); })
                        : this.allTipoResiduos;
        return filteredTipoResiduos;
    }


    getContractsFromProperties(selectedProperties){
        var contracts = [];
        for(let selectedProperty of selectedProperties){
            for( let property of this.data['property']){
                if(selectedProperty==property.id){
                    for(let relatedContract of property.contracts){
                        if (!contracts.includes(relatedContract)) {
                            contracts.push(parseInt(relatedContract));
                        }
                    }
                }
            }
        }
        return contracts;
    }

    getTipoDisposicionFromProperties(selectedProperties){
        
        var tipoDisposicion = [];
        for(let selectedProperty of selectedProperties){
            for( let property of this.data['tipoDisposicion']){
                if(selectedProperty==property.id){
                    for(let relatedTipoDisposicion of property.dadabikd_tipo_disposicion){
                        if (!tipoDisposicion.includes(relatedTipoDisposicion)) {
                            tipoDisposicion.push(parseInt(relatedTipoDisposicion));
                        }
                    }
                }
            }
        }
        return tipoDisposicion;
    }

    getDisposicionFromProperties(selectedProperties){
        
        var disposicion = [];
        
        for(let selectedProperty of selectedProperties){
            for( let property of this.data['planta']){
                
                if(selectedProperty==property.id){
                    
                    for(let relatedDisposicion of property.dadabikd_disposicion_final){
                        if (!disposicion.includes(relatedDisposicion)) {
                            disposicion.push(parseInt(relatedDisposicion));
                        }
                    }
                }
            }
        }
        return disposicion;
    }

    getTipoResiduosFromProperties(selectedProperties){
        
        var tipoResiduo = [];
        
        for(let selectedProperty of selectedProperties){
            for( let property of this.data['tipoResiduo']){
                if(selectedProperty==property.id){
                    
                    for(let relatedTipoResiduos of property.dadabikd_tipo_residuo){
                        if (!tipoResiduo.includes(relatedTipoResiduos)) {
                            tipoResiduo.push(parseInt(relatedTipoResiduos));
                        }
                    }
                }
            }
        }
        return tipoResiduo;
    }




    onlyMonthSelected()
    {
        return this.selectedMonths.length>0 && this.selectedYears.length==0;
    }

    
    setCurrentYear()
    {
        let currentYear = new Date().getFullYear();
        this.setSelectedYears([currentYear]);
    }

    emit(trigger)
    {
        var data = {
            filters : {
                'months' : this.selectedMonths,
                'years' : this.selectedYears,
                'contracts' : this.selectedContracts,
                'desde':this.selectedDesde,
                'hasta':this.selectedHasta,
                'planta':this.selectedDisposicion,
                'tipoResiduo':this.selectedTipoResiduos,
                'tipoDisposicion':this.selectedTipoDisposicion
            },
            eventTrigger : trigger
        }
        let changes = {
            'months' : this.monthsHasChange(),
            'years' : this.yearsHasChange(),
            'contracts' : this.contractsHasChange(),
            //'property' : this.propertiesHasChange()
        }

        this.onChange.emit(data);
    }

    ngOnInit()
    {}
    

    searchContracts(event)
    {
        event.stopPropagation();
        var search = event.target.value;
        this.filterContracts(search);
        this.onContractSearch.emit(search);
    }
    searchDisposicion(event)
    {
        event.stopPropagation();
        var search = event.target.value;
        this.filterDisposicion(search);
        this.onDisposicionSearch.emit(search);
    }

    searchTipoResiduos(event)
    {
        event.stopPropagation();
        var search = event.target.value;
        this.filterTipoResiduos(search);
        this.onResiduosearch.emit(search);
    }
    searchTipoDisposicion(event)
    {
        event.stopPropagation();
        var search = event.target.value;
        this.filterTipoDisposicion(search);
        this.onTipoDisposicionsearch.emit(search);
    }

    checkInvalidSearch()
    {
        var search = this.contractSearch.trim();
        if(search.trim()!='')
        {
            let matches = this.allContracts.filter(function (el) {
                return (
                    el.value.toLowerCase().includes(search.trim().toLowerCase()) || 
                    el.code.toLowerCase().includes(search.trim().toLowerCase())
                );
            })
            if(matches.length==0)
            {
                this.freeContractSearch();
            }
        }
    }


    freeContractSearch()
    {
        this.filterContracts('');
        this.onContractSearch.emit('');
    }


    filterContracts(search)
    {
        this.contractSearch = search;
        if(search == ''){
            this.data['tipoDisposicion'] = this.allContracts;
        }else{
            let contracts = this.getContractsFilteredByProperty();
            this.data['contract'] = contracts.filter(function (el) {
                return (
                el.value.toLowerCase().includes(search.trim().toLowerCase()) || 
                el.code.toLowerCase().includes(search.trim().toLowerCase())
                );
            });
        }
    }

    filterDisposicion(search)
    {
        this.disposicionSearch = search;
        if(search == ''){
            this.data['planta'] = this.allDisposicion;
        }else{
            
            let disposicion = this.getDisposicionFilteredByProperty();
            
            this.data['planta'] = disposicion.filter(function (el) {
                
                return (
                el.dadabikd_disposicion_final.toLowerCase().includes(search.trim().toLowerCase())
                );
            });
        }
    }

    filterTipoDisposicion(search)
    {
        this.tipoDispocisionSearch = search;
        if(search == ''){
            this.data['tipoDisposicion'] = this.allTipoDisposicion;
        }else{

            let tipoDisposicion = this.getTipoDisposicionFilteredByProperty();
            
            this.data['tipoDisposicion'] = tipoDisposicion.filter(function (el) {
              
                return (
                el.dadabikd_tipo_disposicion.toLowerCase().includes(search.trim().toLowerCase())
                );
            });
        }
    }

    
    filterTipoResiduos(search)
    {
        
        this.tipoResiduosSearch = search;
        if(search == ''){
            this.data['tipoResiduo'] = this.allTipoResiduos;
        }else{
            

            let tipoResiduos = this.getTipoResiduosFilteredByProperty();
            
            this.data['tipoResiduo'] = tipoResiduos.filter(function (el) {
                
                return (
                el.dadabikd_tipo_residuo.toLowerCase().includes(search.trim().toLowerCase())
                );
            });
        }
    }

    isMultiple(type:string)
    {
        if(type=='month') return this.multiMonth=='true';
        if(type=='year') return this.multiYear=='true';
        if(type=='contract') return this.multiContract=='true';
        if(type=='disposicion') return this.multiDisposicion=='true';
        if(type=='tipoResiduo') return this.multiTipoResiduos=='true';
        if(type=='tipoDisposicion') return this.multiTipoDisposicion=='true';
        return false;
    }


    onAplyFilterClick()
    {   
        
        var data = {
            filters : {
                'months' : this.selectedMonths,
                'years' : this.selectedYears,
                'contracts' : this.selectedContracts,
                'desde':this.selectedDesde,
                'hasta':this.selectedHasta,
                'planta':this.selectedDisposicion,
                'tipoResiduo':this.selectedTipoResiduos,
                'tipoDisposicion':this.selectedTipoDisposicion
                
            },
            changes : {
                'months' : this.monthsHasChange(),
                'years' : this.yearsHasChange(),
                'contracts' : this.contractsHasChange(),
                //'property' : this.propertiesHasChange()
            }        
        }
        console.log('nuevos filtros.........')
        console.log(data.filters)
        this.updateLastSearch();

        this.aplyFilterEvent.emit(data);
    
    }

    updateLastSearch(){
        this.lastSearch = {
            'months' : this.selectedMonths,
            'years' : this.selectedYears,
            'contracts' : this.selectedContracts,
            'properties' : this.selectedProperties,
            'tipoResiduo':this.selectedTipoResiduos,
            'tipoDisposicion':this.selectedTipoDisposicion,
            'disposicion':this.selectedDisposicion,
            'desde':this.selectedDesde,
            'hasta':this.selectedHasta
        };
    }


    cleanFilters()
    {   
        if( this.isMultiple('month') )   this.monthControl.setValue(this.defaultSearch.months);
        this.selectedMonths = this.defaultSearch.months;

        if( this.isMultiple('year') )    this.yearControl.setValue(this.defaultSearch.years);
        this.selectedYears = this.defaultSearch.years;

        this.selectedContracts = this.defaultSearch.contracts;
        if( this.isMultiple('contract') )    this.contractControl.setValue(this.defaultSearch.contracts);

        this.selectedTipoResiduos = this.defaultSearch.tipoResiduo;
        if( this.isMultiple('tipoResiduo') )    this.tipoResiduosControl.setValue(this.defaultSearch.tipoResiduo);
        
        this.selectedTipoDisposicion = this.defaultSearch.tipoDisposicion;
        if( this.isMultiple('tipoDisposicion') )    this.tipoDisposicionControl.setValue(this.defaultSearch.tipoDisposicion);

        this.selectedDisposicion = this.defaultSearch.disposicion;
        if( this.isMultiple('disposicion') )    this.disposicionControl.setValue(this.defaultSearch.disposicion);

        
        
        this.desdeControl.setValue(null);;
    
        //if( this.isMultiple('month') )   this.monthControl.setValue(this.defaultSearch.months);
        this.selectedDisposicion = this.defaultSearch.disposicion;
        this.selectedTipoResiduos = this.defaultSearch.tipoResiduo;
        this.selectedTipoDisposicion = this.defaultSearch.tipoDisposicion;
        this.selectedDesde = this.defaultSearch.desde;
        this.selectedHasta = this.defaultSearch.hasta;
        this.selectedDesdeMas = this.defaultSearch.desde;
        this.selectedHastaMas = this.defaultSearch.hasta;
        //if( this.isMultiple('year') )    this.yearControl.setValue(this.defaultSearch.years);
        //this.selectedYears = this.defaultSearch.years;

        //if( this.isMultiple('property') )    this.propertyControl.setValue(this.defaultSearch.properties);
        //this.selectedProperties = this.defaultSearch.properties;
        
        this.updateLastSearch();        
    }


    onRemoveFilterClick()
    {
        this.cleanFilters();
        var data = {
            filters : {
                'months' : this.selectedMonths,
                'years' : this.selectedYears,
                'contracts' : this.selectedContracts,
                'properties' : this.selectedProperties,
                'desde':this.selectedDesde,
                'hasta':this.selectedHasta,
                'planta':this.selectedDisposicion,
                'tipoResiduo':this.selectedTipoResiduos,
                'tipoDisposicion':this.selectedTipoDisposicion,
        
            },
            eventTrigger : 'aplyFilter'
        }
        
        this.removeFilterEvent.emit(data);
    }

    //indica si cualquiera de los filtros ha cambiado desde la ultima busqueda
    hasChange( compareWith = 'last')
    {
        let years =  this.hideYear ? false : this.yearsHasChange(compareWith);
        let months =  this.hideMonth ? false : this.monthsHasChange(compareWith);
        return this.contractsHasChange(compareWith) || years || months;
    }

    contractsHasChange( compareWith = 'last' )
    {
        
        let comparison = compareWith=='default' ? this.defaultSearch.contracts : this.lastSearch.contracts;
      
        return this.selectedContracts != comparison;
    }
    /*
    propertiesHasChange( compareWith = 'last' )
    {
        let comparison = compareWith=='default' ? this.defaultSearch.properties : this.lastSearch.properties;
        return this.selectedProperties != comparison;
    }*/

    yearsHasChange( compareWith = 'last' )
    {
        let comparison = compareWith=='default' ? this.defaultSearch.years : this.lastSearch.years;
        return  this.isMultiple('year')
                ? !this.isEquivalent( this.selectedYears,comparison )
                : this.selectedYears != comparison;
    }

    monthsHasChange( compareWith = 'last' )
    {
        let comparison = compareWith=='default' ? this.defaultSearch.months : this.lastSearch.months;
        return  this.isMultiple('month')
                ? !this.isEquivalent( this.selectedMonths,comparison )
                : this.selectedMonths != comparison;
    }



    //Solo para comparar objetos
    isEquivalent(a, b) {
        // Create arrays of property names
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);
    
        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length != bProps.length) {
            return false;
        }
    
        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];
    
            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }
    
        // If we made it this far, objects
        // are considered equivalent
        return true;
    }



    //Element Display functions
    calculateDateDivDisplay()
    {
        if (this.hideMonth==false && this.hideYear==false) {
            return 'row col-lg-4 col-md-12 fltrs';
        }else if (this.isShown('month') && !this.isShown('year')) {
            return 'row col-lg-3 col-md-12 fltrs';
        }else if (!this.isShown('month') && this.isShown('year')) {
            return 'row col-lg-2 col-md-12 fltrs';
        }else{
            return '';
        }
    }

    calculateContractsDivDisplay()
    {
        if (this.hideProperty) {
            return 'row col-lg-4 col-md-12 fltrs';
        } else {
            return 'row col-lg-5 col-md-12 fltrs';
        }
    }

    calculateContractFieldDisplay()
    {
        let p:any = this.hideProperty;
        if (p==true || p=='true') {
            return 'col-md-12 col-xs-12 col-lg-12';
        } else {
            return 'col-md-6 col-xs-12 col-lg-6';
        }
    }

    isShown(type){
        if(type=="month"){
            let month:any = this.hideMonth;
            return month == false || month == 'false';
        }
        if(type=="year"){
            let year:any = this.hideYear;
            return year == false || year == 'false';
        }
        if(type=="property"){
            let property:any = this.hideProperty;
            return property == false || property == 'false';
        }
    }

}


