import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  private baseUrl: string;
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token"),
      }),
    };
  }

  getTasksPermission(): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "getDashboardTasksPermission",
      {},
      this.httpOptions
    );
  }

  get_dashboard_group(user_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_dashboard_group",
      {
        user_id: user_id,
      },
      this.httpOptions
    );
  }


  // PRESENCIA DE PLAGAS
  get_graphic_data(user_id, type): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_graphic_data",
      {
        user_id: user_id,
        type: type,
      },
      this.httpOptions
    );
  }

  get_contracts_profile(user_id, dashboardStyle): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_contracts_profile",
      {
        user_id: user_id,
        dashboardStyle: dashboardStyle,
      },
      this.httpOptions
    );
  }

  get_graphic_filters(type, filters): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_graphic_data_filters",
      {
        type: type,
        filters: filters,
      },
      this.httpOptions
    );
  }

  get_graphic_data_riesgo(user_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_graphic_data_riesgo",
      {
        user_id: user_id,
      },
      this.httpOptions
    );
  }

  get_riesgo_filters(filters): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "riesgo_filters",
      {
        filters: filters,
      },
      this.httpOptions
    );
  }

  //AUDITORIA EXTERNA

  get_dashboard_auditoria_total_month(user_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_dashboard_auditoria_total_month",
      { user_id: user_id },
      this.httpOptions
    );
  }

  get_dashboard_auditoria_total_anio(user_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_dashboard_auditoria_total_anio",
      { user_id: user_id },
      this.httpOptions
    );
  }

  get_dashboard_auditoria_distribucion(user_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_dashboard_auditoria_distribucion",
      { user_id: user_id },
      this.httpOptions
    );
  }

  // filtros auditoria

  flt_total_month(filters): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_total_month",
      {
        filters: filters,
      },
      this.httpOptions
    );
  }

  flt_total_anio(filters): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_total_anio",
      {
        filters: filters,
      },
      this.httpOptions
    );
  }

  flt_distribucion(filters): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_distribucion",
      {
        filters: filters,
      },
      this.httpOptions
    );
  }

  //ACTIVIDAD ROEDORES
  get_dashboard_act_roedores(user_id, ubicacion): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_dashboard_act_roedores",
      {
        user_id: user_id,
        ubicacion: ubicacion,
      },
      this.httpOptions
    );
  }

  get_dashboard_captura(user_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_dashboard_acti_roedores_captura",
      { user_id: user_id },
      this.httpOptions
    );
  }

  get_dashboard_consumo(user_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_dashboard_acti_roedores_consumo",
      { user_id: user_id },
      this.httpOptions
    );
  }
  // FILTROS ACTIVIDAD ROEDORES
  flt_dashboard_act_roedores(
    user_id,
    ubicacion,
    year,
    month,
    cont_id
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_dashboard_act_roedores",
      {
        user_id: user_id,
        ubicacion: ubicacion,
        year: year,
        month: month,
        cont_id: cont_id,
      },
      this.httpOptions
    );
  }

  flt_dashboard_captura(user_id, year, month, cont_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_dashboard_acti_roedores_captura",
      {
        user_id: user_id,
        year: year,
        month: month,
        cont_id: cont_id,
      },
      this.httpOptions
    );
  }

  flt_dashboard_consumo(user_id, year, month, cont_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_dashboard_acti_roedores_consumo",
      {
        user_id: user_id,
        year: year,
        month: month,
        cont_id: cont_id,
      },
      this.httpOptions
    );
  }

  // SATURACION TUV
  get_tuv_semanal(user_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "tuv_semanal",
      {
        user_id: user_id,
      },
      this.httpOptions
    );
  }

  get_tuv_mensual(user_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "tuv_mensual",
      {
        user_id: user_id,
      },
      this.httpOptions
    );
  }

  get_tuv_semanal_plaga(user_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "tuv_semanal_plaga",
      {
        user_id: user_id,
      },
      this.httpOptions
    );
  }

  // FILTROS tuv

  flt_tuv_semanal(user_id, year, month, cont_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_tuv_semanal",
      {
        user_id: user_id,
        year: year,
        month: month,
        cont_id: cont_id,
      },
      this.httpOptions
    );
  }

  flt_tuv_mensual(user_id, year, month, cont_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_tuv_mensual",
      {
        user_id: user_id,
        year: year,
        month: month,
        cont_id: cont_id,
      },
      this.httpOptions
    );
  }

  flt_tuv_semanal_plaga(user_id, year, month, cont_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_tuv_semanal_plaga",
      {
        user_id: user_id,
        year: year,
        month: month,
        cont_id: cont_id,
      },
      this.httpOptions
    );
  }

  addCommentDashboard(dashboard_name, comentario): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "add_comment_dashboard",
      {
        dashboard_name: dashboard_name,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  //listar comentario
  listCommentDashboard(
    dashboard_tag,
    user_id,
    year,
    month,
    cont_id
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "list_comment_dashboard",
      {
        dashboard_tag: dashboard_tag,
        user_id: user_id,
        year: year,
        month: month,
        cont_id: cont_id,
      },
      this.httpOptions
    );
  }

  // EDITAR COMENTARIO DE LOS DASHBOARD
  editCommentActiRoeMesZona(
    user_id,
    acrosemezo_id,
    consumo,
    captura,
    novedad,
    factor_riesgo,
    accion_rentokil
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "editCommentActiRoeMesZona",
      {
        user_id: user_id,
        acrosemezo_id: acrosemezo_id,
        consumo: consumo,
        captura: captura,
        novedad: novedad,
        factor_riesgo: factor_riesgo,
        accion_rentokil: accion_rentokil,
      },
      this.httpOptions
    );
  }

  editCommentCompAnualCapturaMes(user_id, id, comentario): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "editCommentCompAnualCapturaMes",
      {
        user_id: user_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  editCommentCompAnualConsumoMes(user_id, id, comentario): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "editCommentCompAnualConsumoMes",
      {
        user_id: user_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  editCommentTUVSemanaPlaga(
    user_id,
    id,
    novedad,
    factor_riesgo,
    accion_rentokil
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "editCommentTUVSemanaPlaga",
      {
        user_id: user_id,
        id: id,
        novedad: novedad,
        factor_riesgo: factor_riesgo,
        accion_rentokil: accion_rentokil,
      },
      this.httpOptions
    );
  }

  editCommentTUVSemanal(user_id, id, comentario): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "editCommentTUVSemanal",
      {
        user_id: user_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  editCommentTUVMensual(user_id, id, comentario): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "editCommentTUVMensual",
      {
        user_id: user_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  listAllCommentTask(cont_id, task_page): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "list_all_comment_task",
      {
        cont_id: cont_id,
        task_page: task_page,
        showTasksMonthsAgo: sessionStorage.getItem("showTasksMonthsAgo"),
      },
      this.httpOptions
    );
  }
  // COMPLETAR TAREAS
  completeTaskActiRoeMesZona(
    user_id,
    daphagrata_id,
    acrosemezo_id,
    consumo,
    captura,
    novedad,
    factor_riesgo,
    accion_rentokil
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "completeTaskEditActiRoeMesZona",
      {
        user_id: user_id,
        daphagrata_id: daphagrata_id,
        acrosemezo_id: acrosemezo_id,
        consumo: consumo,
        captura: captura,
        novedad: novedad,
        factor_riesgo: factor_riesgo,
        accion_rentokil: accion_rentokil,
      },
      this.httpOptions
    );
  }

  completeTaskCompAnualCapturaMes(
    user_id,
    daphagrata_id,
    id,
    comentario
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "completeTaskEditCompAnualCapturaMes",
      {
        user_id: user_id,
        daphagrata_id: daphagrata_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  completeTaskCompAnualConsumoMes(
    user_id,
    daphagrata_id,
    id,
    comentario
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "completeTaskEditCompAnualConsumoMes",
      {
        user_id: user_id,
        daphagrata_id: daphagrata_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  completeTaskTUVSemanaPlaga(
    user_id,
    daphagrata_id,
    id,
    novedad,
    factor_riesgo,
    accion_rentokil
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "completeTaskEditTUVSemanaPlaga",
      {
        user_id: user_id,
        daphagrata_id: daphagrata_id,
        id: id,
        novedad: novedad,
        factor_riesgo: factor_riesgo,
        accion_rentokil: accion_rentokil,
      },
      this.httpOptions
    );
  }

  completeTaskRTUSemanaPlaga(
    user_id,
    daphagrata_id,
    id,
    comentario
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "completeTaskEditRTUSemanaPlaga",
      {
        user_id: user_id,
        daphagrata_id: daphagrata_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }
  completeTaskMSTSemanaPlaga(
    user_id,
    daphagrata_id,
    id,
    comentario
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "completeTaskEditMSTSemanaPlaga",
      {
        user_id: user_id,
        daphagrata_id: daphagrata_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  completeTaskTUVSemanal(
    user_id,
    daphagrata_id,
    id,
    comentario
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "completeTaskEditTUVSemanal",
      {
        user_id: user_id,
        daphagrata_id: daphagrata_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  completeTaskRTUSemanal(
    user_id,
    daphagrata_id,
    id,
    comentario
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "completeTaskEditRTUSemanal",
      {
        user_id: user_id,
        daphagrata_id: daphagrata_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  completeTaskMSTSemanal(
    user_id,
    daphagrata_id,
    id,
    comentario
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "completeTaskEditMSTSemanal",
      {
        user_id: user_id,
        daphagrata_id: daphagrata_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  completeTaskTUVMensual(
    user_id,
    daphagrata_id,
    id,
    comentario
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "completeTaskEditTUVMensual",
      {
        user_id: user_id,
        daphagrata_id: daphagrata_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  completeTaskRTUMensual(
    user_id,
    daphagrata_id,
    id,
    comentario
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "completeTaskEditRTUMensual",
      {
        user_id: user_id,
        daphagrata_id: daphagrata_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }
  completeTaskMSTMensual(
    user_id,
    daphagrata_id,
    id,
    comentario
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "completeTaskEditMSTMensual",
      {
        user_id: user_id,
        daphagrata_id: daphagrata_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  listGraphicTittle(): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "listGraphicTittle",
      {},
      this.httpOptions
    );
  }

  getSpeciesGroups(filters): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "getSpeciesGroups",
      {
        filters: filters,
      },
      this.httpOptions
    );
  }

  getOtherSpeciesGraphicData(filters): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "getOtherSpeciesGraphicData",
      {
        filters: filters,
      },
      this.httpOptions
    );
  }

  getTuvSaturationBySpeciesGroup(years, months, contracts): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_tuv_saturation_by_species_group",
      {
        years: years,
        months: months,
        contracts: contracts,
      },
      this.httpOptions
    );
  }

  getContractName(contId): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "getContractName",
      {
        contId: contId,
      },
      this.httpOptions
    );
  }

  getProperties(): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "get_properties",
      {
        relatedTo: ["CONTRACTS"],
      },
      this.httpOptions
    );
  }

  //FILTROS Feromonas
  getFeromonasMstBySpeciesGroup(years, months, contracts): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "getFeromonasMstBySpeciesGroup",
      {
        years: years,
        months: months,
        contracts: contracts,
      },
      this.httpOptions
    );
  }

  flt_feromona_mst_semanal(user_id, year, month, cont_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_dashboard_feromonas_mst_semanal",
      {
        user_id: user_id,
        year: year,
        month: month,
        cont_id: cont_id,
      },
      this.httpOptions
    );
  }

  flt_feromona_mst_mensual(user_id, year, month, cont_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_dashboard_feromonas_mst_mensual",
      {
        user_id: user_id,
        year: year,
        month: month,
        cont_id: cont_id,
      },
      this.httpOptions
    );
  }

  getFeromonasRtuBySpeciesGroup(years, months, contracts): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "getFeromonasRtuBySpeciesGroup",
      {
        years: years,
        months: months,
        contracts: contracts,
      },
      this.httpOptions
    );
  }

  flt_feromona_rtu_semanal(user_id, year, month, cont_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_dashboard_feromonas_rtu_semanal",
      {
        user_id: user_id,
        year: year,
        month: month,
        cont_id: cont_id,
      },
      this.httpOptions
    );
  }

  flt_feromona_rtu_mensual(user_id, year, month, cont_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "flt_dashboard_feromonas_rtu_mensual",
      {
        user_id: user_id,
        year: year,
        month: month,
        cont_id: cont_id,
      },
      this.httpOptions
    );
  }

  editCommentRTUSemanaPlaga(user_id, id, comentario): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "editCommentRTUSemanaPlaga",
      {
        user_id: user_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  editCommentRTUSemanal(user_id, id, comentario): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "editCommentRTUSemanal",
      {
        user_id: user_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  editCommentRTUMensual(user_id, id, comentario): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "editCommentRTUMensual",
      {
        user_id: user_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  editCommentMSTSemanaPlaga(user_id, id, comentario): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "editCommentMSTSemanaPlaga",
      {
        user_id: user_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  editCommentMSTSemanal(user_id, id, comentario): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "editCommentMSTSemanal",
      {
        user_id: user_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }

  editCommentMSTMensual(user_id, id, comentario): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "editCommentMSTMensual",
      {
        user_id: user_id,
        id: id,
        comentario: comentario,
      },
      this.httpOptions
    );
  }
  getResidusoServicios(contracts, desde, hasta): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "residuosServicios",
      {
        contracts: contracts,
        desde: desde,
        hasta: hasta,
      },
      this.httpOptions
    );
  }
  exportResiduosServicios(
    filter,
    clieId,
    segtyId,
    contractId,
    page
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "residuosServicios/export",
      {
        filter: filter,
        clieId: clieId,
        segtyId: segtyId,
        contId: contractId,
      },
      this.httpOptions
    );
  }

  getresiduosFiltrosKdm(): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "residuosFiltrosKdm",
      {},
      this.httpOptions
    );
  }
  getresiduosPlanta(
    contracts,
    desde,
    hasta,
    planta,
    tipoResiduo,
    tipoDisposicion
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "residuosPlanta",
      {
        contracts: contracts,
        desde: desde,
        hasta: hasta,
        planta: planta,
        tipoResiduo: tipoResiduo,
        tipoDisposicion: tipoDisposicion,
      },
      this.httpOptions
    );
  }

  getresiduosTipo(
    contracts,
    desde,
    hasta,
    planta,
    tipoResiduo,
    tipoDisposicion
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "residuosTipo",
      {
        contracts: contracts,
        desde: desde,
        hasta: hasta,
        planta: planta,
        tipoResiduo: tipoResiduo,
        tipoDisposicion: tipoDisposicion,
      },
      this.httpOptions
    );
  }

  getresiduosTipoTratamiento(
    contracts,
    desde,
    hasta,
    planta,
    tipoResiduo,
    tipoDisposicion
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "residuosTipoTratamiento",
      {
        contracts: contracts,
        desde: desde,
        hasta: hasta,
        planta: planta,
        tipoResiduo: tipoResiduo,
        tipoDisposicion: tipoDisposicion,
      },
      this.httpOptions
    );
  }

  getresiduosGestionadosAnio(
    contracts,
    desde,
    hasta,
    planta,
    tipoResiduo,
    tipoDisposicion
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "residuosGestionadosAnio",
      {
        contracts: contracts,
        desde: desde,
        hasta: hasta,
        planta: planta,
        tipoResiduo: tipoResiduo,
        tipoDisposicion: tipoDisposicion,
      },
      this.httpOptions
    );
  }
  getresiduosImagen(dadabikdi_id): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "residuosObtenerImagen",
      {dadabikdi_id:dadabikdi_id},
      this.httpOptions
    );
  }
}
