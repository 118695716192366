import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

// create interface for data in sendGuideFile(data)
export interface MassUpGuideData {
  CONTRATO_CODIGO: string;
  NOMBRE_ARCHIVO: string;
  LINEA_NEGOCIO: string;
  SUBTIPO_DOCUMENTO: string;
}

@Injectable({
  providedIn: "root",
})
export class MassUploadService {
  private baseUrl: string;
  private httpOptions: Object;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      }),
    };
  }
  // listado de servicios

  // 1. obtener los repositories con su cliente, compañia, contrtato, etc asociados
  getDemoData(): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "massUpload/getData",
      {},
      this.httpOptions
    );
  }

  sendGuideFile(data: MassUpGuideData, userId: number): Observable<any[]> {
    const dataWithId = {
      data,
      userId,
    };
    return this.http.post<any[]>(
      this.baseUrl + "massUpload/validate",
      dataWithId,
      this.httpOptions
    );
  }

  executeMassUpload(form: FormData): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + "massUpload/executeMassUpload",
      form,
      {
        headers: new HttpHeaders({
          Authorization: "Bearer " + sessionStorage.getItem("token")
        }),
      }
    );
  }
}
