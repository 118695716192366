import { Component, OnInit, Input, Output, HostBinding, NgZone, ChangeDetectorRef } from '@angular/core';
import { EventEmitter } from 'events';
import { ChartInterface } from 'src/app/interfaces/chart-interface';
import {MatCardModule} from '@angular/material/card';
import { ColorHelper } from '@swimlane/ngx-charts';

@Component({
  selector: 'horizontal-chart',
  templateUrl: './horizontal-chart.component.html',
  styleUrls: ['./horizontal-chart.component.css']
})


export class HorizontalChartComponent implements OnInit {

  public title = "";
  public dataSource : any = [];
  
  public activeEntries: any[];
  public chartData: { name: string, series: { name: string, value?: string | number }[] }[];
  public legendNames: any[];
  public colors: ColorHelper;
  public customizedLegend = false;

  public legendLabelActivate(item: any): void {
      this.activeEntries = [item];
  }

  public legendLabelDeactivate(item: any): void {
      this.activeEntries = [];
  }
  

  formatPercent(val) {
    if (val >= 100) {
      return val + '%';
    }
    return val+1;
  } 
  formatNumber(numero){
    return new Intl.NumberFormat("es-CL").format(numero)+'';
  }
  
  setCustomizedLegend(value){
    this.customizedLegend = value;
  }
  public index : number;
  public fingerprint : string;
  public selfRef: HorizontalChartComponent;
  public isLoading = true;
  public style : any;


  
  public chartStyles = {
    'distribucion' : {
      view : [1030, 400]
      , scheme :  {domain: ['#7859EB','#FB886F','#F7FB7B','#78D35F','#C98ECF','#7FD9F7','#F2B25F','#B2EA70']}
      , legend :  true
      , legendTitle : 'Mes'
      , xAxis : true
      , yAxis : true
      , showYAxisLabel : true
      , yAxisLabel : ''
      , barPadding : 2
      , xScaleMax : 100
      , showDataLabel : false
    }
    , 'plaga' : {
      view : [900, 300]
      , scheme :  {domain: ['#1F8303', '#F9FF1C','#F02E04']}
      , legend :  true
      , legedTitle : 'Riesgo'
      , xAxis : true
      , yAxis : true
      , showYAxisLabel : false
      , yAxisLabel : ''
      , barPadding : 8
      , xScaleMax : 100
      , showDataLabel : false
    }, 'tuv' : {
      view : [700, 240]
      , scheme :  {domain: ['#7859EB','#FB886F','#F7FB7B','#78D35F','#C98ECF','#7FD9F7','#F2B25F','#B2EA70']}
      , legend :  true
      , legendTitle : 'Mes'
      , xAxis : true
      , yAxis : true
      , showYAxisLabel : false
      , yAxisLabel : ''
      , barPadding : 2
      , xScaleMax : 1
      , showDataLabel : true
    },
    'disposicion' : {
      view : [1030, 400]
      , scheme :  {domain: ['#7859EB','#FB886F','#F7FB7B','#78D35F','#C98ECF','#7FD9F7','#F2B25F','#B2EA70']}
      , legend :  true
      , legendTitle : ''
      , xAxis : true
      , yAxis : true
      , showYAxisLabel : true
      , showXAxisLabel : true
      , yAxisLabel : ''
      , xAxisLabel : ''
      , barPadding : 2
      , xScaleMax : 1
      , showDataLabel : true
      , rotateXAxisTicks : true
      
    },
    'tipoDisposicion' : {
      view : [1030, 400]
      , scheme :  {domain: ['#7859EB','#FB886F','#F7FB7B','#78D35F','#C98ECF','#7FD9F7','#F2B25F','#B2EA70']}
      , legend :  true
      , legendTitle : ''
      , xAxis : true
      , yAxis : true
      , showYAxisLabel : true
      , showXAxisLabel : true
      , yAxisLabel : ''
      , xAxisLabel : ''
      , barPadding : 2
      , xScaleMax : 1
      , showDataLabel : true
    },
    
  };
  public type : string;
 
  @HostBinding('class.col-md-4') isColMd4 : boolean;
  @HostBinding('class.col-lg-4') isColLg4 : boolean;
  @HostBinding('class.col-md-6') isColMd6 : boolean;
  @HostBinding('class.col-lg-6') isColLg6 : boolean;
  @HostBinding('class.col-md-8') isColMd8 : boolean;
  @HostBinding('class.col-lg-8') isColLg8 : boolean;
  @HostBinding('class.col-md-12') isColMd12 : boolean;
  @HostBinding('class.col-lg-12') isColLg12 : boolean;


  //interface for Parent-Child interaction
  public parentReference : ChartInterface;
  isDataValid = true;

/* 
  _time;
  get time() { return this._time; }
 */

  constructor(/* public zone : NgZone,  */private detector : ChangeDetectorRef ) {
    this.fingerprint = Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2);
  }

  // @Input('type') type : string; 
  // @Output() onLoad = new EventEmitter();


  ngOnInit() {
  }


  xAxisTickFormatting = (value: number) => {
    if (Math.floor(value) !== value) {
      return '';
    }
    return value;
  }

  setClasses(classes){
    if( !Array.isArray(classes) ) return false;
    if(classes.includes('col-lg-4')) this.isColLg4 = true;
    if(classes.includes('col-md-4')) this.isColMd4 = true;
    if(classes.includes('col-lg-6')) this.isColLg6 = true;
    if(classes.includes('col-md-6')) this.isColMd6 = true;
    if(classes.includes('col-lg-8')) this.isColLg8 = true;
    if(classes.includes('col-md-8')) this.isColMd8 = true;
    if(classes.includes('col-lg-12')) this.isColLg12 = true;
    if(classes.includes('col-md-12')) this.isColMd12 = true;
  }

  async setDataSource(dataSource){
    this.isLoading = false;
    this.dataSource = dataSource;
    this.validateData();
    
    if(this.customizedLegend==true){
      
      await this.getCustomizedLegend();
      
       
    }
  }

  getCustomizedLegend(){
    let legendTotal = [] as any;
    this.legendNames = [];
    this.dataSource.forEach(function(categoria){
      
      categoria.series.forEach(function(serie){
          
          if(typeof(legendTotal[serie.name])=='undefined'){
            legendTotal[serie.name] = serie.value;
          }else{
            legendTotal[serie.name]+= serie.value;
          }
      });
    });
    
    
    for (var arrayIndex in legendTotal) {
        
        this.legendNames.push(arrayIndex+' ('+legendTotal[arrayIndex]+')');
    } 
    this.colors = new ColorHelper(this.style.scheme, 'ordinal', this.legendNames, this.style.scheme);
  }


  setType(type){
    this.type = type;
    this.style = this.chartStyles[type];
  }

  getLegendTitle(){
    return this.style.legendTitle;
  }

  setCustomStyle(style){
    this.style = style; 
  }

  validateData(){
    var data = this.dataSource;
    var barCount = 0;
    data.map((stack)=>{
      stack.series.map((bar)=>{
        if(bar.value>0) barCount++;
      });
    });
    this.isDataValid = barCount>0;
  }

}
